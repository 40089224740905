import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { DraftTrainingPackage, Task, TaskGroup, Model, Artifact } from 'src/app/models/training-package.model';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ArtifactsService } from 'src/app/services/artifacts.service';
import { MatDialog } from '@angular/material/dialog';
import { AddEntityDialogComponent, BtlSharedLibraryService } from 'btl-shared-library';
import { AddArtifactDialogComponent } from '../add-artifact-dialog/add-artifact-dialog.component';
import e from 'express';
@Component({
  selector: 'app-draft-artifact-section',
  templateUrl: './draft-artifact-section.component.html',
  styleUrls: ['./draft-artifact-section.component.scss']
})
export class DraftArtifactSectionComponent implements OnInit {

  taskName: string = '';
  descriptionValue: string = "";
  maxCharCount: number = 300;
  currentModel: string = "";
  currentTaskGroup: TaskGroup | null;
  currentTask: Task | null
  currentDraftPackage: DraftTrainingPackage | null;
  artifactsTableSize: number = 0;
  artifacts: any[] = [];
  approvedArtifactsList: any[] = [];
  editCount: number = 0;
  rowData:any;
  isDeleteTaskVisible = false;
  currentTaskGroups: any[] = [];

  constructor(private sharedDataService: SharedDataService,
    private router: Router,
    private artifactsService: ArtifactsService,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private btlSharedLibraryService: BtlSharedLibraryService,
    public dialog: MatDialog) {
    this.currentTaskGroup = null;
    this.currentTask = null;
    this.currentDraftPackage = null;
  }


  ngOnInit(): void {
    console.log("is task visible:", this.isDeleteTaskVisible)
    //document.addEventListener('click', this.handleDeleteTaskEvent.bind(this));
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
      }
    });
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd) {
        this.loadTaskDetails();
      }
    });
    this.loadTaskDetails();
    

    // subscribe to edit count
    this.sharedDataService.editCount$.subscribe(count => {
      this.editCount = count;
    })

    this.sharedDataService.rowData$.subscribe(pkg => {
      this.rowData = pkg;
    })
    this.sharedDataService.updateModelFilters(this.rowData);
    this.sharedDataService.updateTaskGroups(this.rowData.taskgroup);
    this.sharedDataService.updateEditPackageHeaders(true);
    this.descriptionValue = '';
   
  }

  loadTaskDetails() {
    // subscribe to all approved artifacts
    this.sharedDataService.allApprovedArtifacts$.subscribe((artifacts) => {
      if (artifacts === null || artifacts === undefined || artifacts.length <= 0) {
        // api call
        this.getAllApprovedArtifacts();
      } else {
        this.approvedArtifactsList = artifacts;
      }
    })

    // subscribe to current model
    this.sharedDataService.currentModel$.subscribe((model) => {
      this.currentModel = model;
    })

    // subscribe to current task.
    this.sharedDataService.currentTask$.subscribe((task) => {
      this.currentTask = task;
      if (task != null) {
        this.descriptionValue = task.taskDescription;
      }
    })

    // set artifacts list
    if (this.currentTask?.artifacts != undefined) {
      this.artifacts = this.currentTask?.artifacts;
      this.sharedDataService.updateArtifacts(this.artifacts);
    } else {
      this.artifacts = []
      this.sharedDataService.updateArtifacts(this.artifacts);
    }


    // subscribe to current task-group
    this.sharedDataService.currentTaskGroup$.subscribe((taskGroup) => {
      this.currentTaskGroup = taskGroup;
    })

    //subscribe to current task-group list
    this.sharedDataService.taskGroups$.subscribe(taskGroups => {
      this.currentTaskGroups = taskGroups;
    })

    // subscribe to draft-package
    this.sharedDataService.currentEditDraftPackage$.subscribe((draft) => {
      this.currentDraftPackage = draft;
    })

    // update the current task to draft-package
    console.log("taskid of current task:", this.currentTask?.taskId)
    console.log("this.currentTask != null && this.currentTask.taskId == ", this.currentTask != null && this.currentTask?.taskId == undefined)

    if (this.currentTask != null && this.currentTask?.taskId == undefined) {
      console.log("Current task:", this.currentTask);
      //this.addTaskToDraftPackage(this.currentTask,'add');
      console.log("Current draft package:", this.currentDraftPackage)
    }
  }
  updateTaskDescription(): void {
    console.log("Description updates:", this.descriptionValue)
    if (this.currentTask != null) {
      this.currentTask.taskDescription = this.descriptionValue;
      this.sharedDataService.updateEditCount(this.editCount);
      //this.addTaskToDraftPackage(this.currentTask,'update');
    }
  }

  addTaskToDraftPackage(currentTask: Task, action: string): void {
    console.log("Add task start-model::", this.currentModel)
    console.log("task-group::", this.currentTaskGroup)

    this.sharedDataService.updateEditCount(this.editCount);
    let existingModel: Model | undefined;
    let existingTaskGroups: TaskGroup[] | undefined;

    // get existing model
    existingModel = this.currentDraftPackage?.models?.find(m => m.model === this.currentModel);

    // get existing task-group list from model
    if (existingModel != undefined) {
      existingTaskGroups = existingModel.taskGroups;
    }

    // udpate current task's properties and update the task in the current task list of the current taskgroup
    if (action.includes('update')) {
      const updatedTask: Task = { ...currentTask };
      const taskIndex = this.currentTaskGroup?.tasks?.findIndex(task => task === currentTask);
      if (taskIndex !== undefined && taskIndex !== -1) {
        this.currentTaskGroup?.tasks?.splice(taskIndex, 1, updatedTask);
      }
    }

    // replace the current task-group in the exitsing-task-group list
    if (existingTaskGroups !== undefined && this.currentTaskGroup !== null) {
      const index = existingTaskGroups.findIndex(group => group === this.currentTaskGroup);
      if (index !== -1) {
        existingTaskGroups[index] = this.currentTaskGroup;
      }
    }

    // replace the existing task-groups in the existing model
    if (existingModel !== undefined) {
      existingModel.taskGroups = existingTaskGroups;
    }

    // replace the existing model in the draft
    if (existingModel !== undefined && this.currentDraftPackage != null && this.currentDraftPackage.models != null) {
      const index = this.currentDraftPackage?.models?.findIndex(m => m === existingModel);
      if (index !== undefined && index !== -1) {
        this.currentDraftPackage.models[index] = existingModel;
      }
    }
    console.log("UDPATED DRAFT PACKAGE WITH TASK:::", this.currentDraftPackage)
  }

  getAllApprovedArtifacts() {
    this.artifactsService.getApprovedArtifactsFromCommitmentService(0, 10000).subscribe({
      next: (data) => {
        console.log("all approved artifacts from commitment & tp ::", data);
        if (data.status === '200 OK') {
          this.approvedArtifactsList = data.data;
          let updatedList: any[] = [];
          this.approvedArtifactsList.forEach(artifact => {
            if (artifact != null) {
              artifact.action = "";
              updatedList.push(artifact);
            }
          });
          this.approvedArtifactsList = updatedList;
          this.sharedDataService.updateApprovedArtifacts(this.approvedArtifactsList);
        }
      },
      error: (e) => {
        console.error(e);
        const errorMessage = e.error && e.error.message ? e.error.message : "An error occurred during the request.";
        console.log("Error ::", errorMessage);
      }
    })
  }

  addArtifactsToTask(artifactToAdd: any): void {
    artifactToAdd = this.getArtifactDataBasedOnDialog(artifactToAdd);
    let firstArtifact = false;
    console.log("Add Artifact to task::", artifactToAdd);
    if (artifactToAdd != null && "artifactId" in artifactToAdd && artifactToAdd.artifactId != "") {
      console.log("[=>] Updating artifacts:", this.currentTask?.artifacts?.length);
      let updatedTask = this.currentTask;
      let existingTaskArtifacts = updatedTask?.artifacts;

      console.log("Existing artifacts:", existingTaskArtifacts)

      // initialize if artifact of task is null
      if (existingTaskArtifacts == null) {
        existingTaskArtifacts = [];
      }

      if (existingTaskArtifacts.length === 0) {
        console.log("Artifacts List  was null so add first time");
        // notify subscribers-> <artifact-section-component>
        this.artifacts.push(artifactToAdd);
        this.sharedDataService.updateArtifacts(this.artifacts);
        firstArtifact = true;
      }
      let doesArtifactExists = existingTaskArtifacts.filter(artifact => artifact.artifactId == artifactToAdd.artifactId);
      console.log("doesArtifactExists::", doesArtifactExists);
      // only add if artifactId doesn't exists.
      if (doesArtifactExists.length === 0) {

        console.log("Artifact not present in task adding it.");
        // update current task
        existingTaskArtifacts.push(artifactToAdd);

        // update the final draft package  
        if (this.currentTask != null) {
          this.currentTask.artifacts = existingTaskArtifacts;
          console.log("Updating final draft");
          this.addTaskToDraftPackage(this.currentTask, 'update')
        }

      } else {
        if (!firstArtifact) {
          // show error artifact already exits in the task.
          this.btlSharedLibraryService.updateStatusMessage(true, `Artifact ${artifactToAdd.fileName} already exists`, false);
          window.scrollTo(0, 0);
        }
      }
      this.reOrderArtifacts(this.artifacts);
      this.sharedDataService.updateArtifacts(this.artifacts);
    } else {
      // show error artifact doesn;t exits in commitment service

      this.btlSharedLibraryService.updateStatusMessage(
        true,
        `Artifact ${(artifactToAdd?.fileName && artifactToAdd?.revision)
          ? `${artifactToAdd.fileName} (revision - ${artifactToAdd.revision})`
          : artifactToAdd?.artifactKey || 'Unknown'
        } is not present or approved`,
        false
      );

      window.scrollTo(0, 0);
    }
  }


  handleArtifactUpdate(updatedArtifactEvent: { updatedArtifact: any, action: string }) {
    this.sharedDataService.updateEditCount(this.editCount);
    const updatedArtifact = updatedArtifactEvent.updatedArtifact;
    const action = updatedArtifactEvent.action;
    console.log("Artifact Action::", action);

    if (action === 'update') {
      console.log('Updated Artifact Event:', updatedArtifact);
      // 2-way databinding handles field udpated
    } else if (action === 'delete') {
      if (this.artifacts != null) {
        console.log("Updated artifacts with delete action::", this.artifacts);
        // remove updated artifact from view.
        let updatedArtifactList = this.artifacts.filter(artifact => artifact.artifactId !== updatedArtifact.artifactId);
        this.artifacts = updatedArtifactList;

        // update shared data
        this.sharedDataService.updateArtifacts(this.artifacts);

        // update shared currentDraftTP
        this.sharedDataService.updateCurrentEditDraftPackage(this.currentDraftPackage);
      }
    }
    else if (action === 'revision delete') {
      console.log("Updated artifacts with revision update action::", this.artifacts);
      console.log('revision updated Artifact Event:', updatedArtifact);

      // update current task
      let updatedTask = this.currentTask;
      let existingTaskArtifacts = updatedTask?.artifacts;
      if (existingTaskArtifacts != null) {
        console.log("Existing artifacts:", existingTaskArtifacts)
        existingTaskArtifacts.push(updatedArtifact);
      }

      this.sharedDataService.updateArtifacts(this.artifacts.push(updatedArtifact));
      // update the final draft package  
      if (this.currentTask != null) {
        this.currentTask.artifacts = existingTaskArtifacts;
        console.log("Updating final draft");
        this.addTaskToDraftPackage(this.currentTask, 'update')
      }
    }
    this.reOrderArtifacts(this.artifacts);
    console.log("Updated Artifact list:", this.artifacts);
    console.log("Updated draft package:", this.currentDraftPackage);
  }

  handleReOrderArtifacts(reOrderedArtifacts: any) {
    this.sharedDataService.updateEditCount(this.editCount);
    this.reOrderArtifacts(reOrderedArtifacts);
  }

  reOrderArtifacts(artifacts: any) {
    console.log("artifacts:", artifacts)
    if (this.currentTask) {
      this.currentTask.artifactOrder = artifacts
        .filter((artifact: any) => artifact !== undefined) // Filter out undefined artifacts
        .map((artifact: any) => artifact.artifactId);
      console.log("New Artifact Order:", this.currentTask?.artifactOrder);
      console.log("updated training package", this.currentDraftPackage)
    }
  }

  cleanArtifactData(artifactToAdd: any, action: string): any {
    // this converts artifact data as per api request
    // new denotes it's being added with existing data,
    // update , when title or description is udpated.
    return {
      artifactId: artifactToAdd.artifactId,
      title: artifactToAdd.title,
      description: artifactToAdd.description,
      fileName: artifactToAdd.fileName,
      action: action === 'new' ? action : 'update',
      artifactStatus: artifactToAdd.artifactStatus === null ? "NEW" : artifactToAdd.artifactStatus,
      fresh: artifactToAdd.fresh
    }
  }

  
  openArtifactDialog(): void {
    const dialogRef = this.dialog.open(AddArtifactDialogComponent,{
      width: '65%',
      height: '85%'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== undefined) {
        result.forEach((artifact: { artifactId: any; }) => {
          console.log('Artifact ID:', artifact.artifactId);
          this.addArtifactsToTask(artifact.artifactId);

        });
      } else {
        console.log('Dialog closed without result');
      }
    });
  }

  getArtifactDataBasedOnDialog(artifactId: any) {
    let artifact = null;
    if (artifactId !== "") {
      // search artifact with artifactId : can be used to directly call a api;
      artifact = this.approvedArtifactsList.find(artifact => artifact.artifactId === artifactId);
    }
    return artifact != null ? this.cleanArtifactData(artifact, 'new') : null;
  }

  handleDeleteTask() {
    this.isDeleteTaskVisible = !this.isDeleteTaskVisible;
  }

  deleteTask() {
    console.log("Delete task:", this.currentTask)
    // add delete action property on current task.

    this.sharedDataService.updateEditCount(this.editCount);
    console.log("Current taskgroup:", this.currentTaskGroup)
    if (this.currentTaskGroup?.tasks != null) {
      let updatedTaskList = this.currentTaskGroup?.tasks?.map(task => {
        if (task.taskId === this.currentTask?.taskId) {
          return { ...task, action: 'delete' };
        } else {
          return task;
        }
      });

      this.currentTaskGroup.tasks = updatedTaskList;
      this.sharedDataService.updateTaskGroup(this.currentTaskGroup)

      console.log("Updated task-group::", this.currentTaskGroup);
      console.log("currnet model:", this.currentModel);
      console.log("Currnet all task-groups:", this.currentTaskGroups);
      console.log("Current draft:", this.currentDraftPackage)

      //remove the deleted task from view
      this.currentTask = null;
      this.setTaskGroupAndTaskView();
      this.handleDeleteTask();
    }
  }

  setTaskGroupAndTaskView() {
    // update the task view as per selected task-group .
    // set first task-group & task in view.
    if (this.currentDraftPackage != null && this.currentTaskGroups.length > 0 && this.currentTaskGroups[0].tasks.length > 0) {
      let taskGroup = this.currentTaskGroups[0];
      let task = taskGroup.tasks[0]
      this.sharedDataService.updateTask(task);
      this.router.navigate(['packages',this.currentDraftPackage.trainingPackageId, 'taskGroup', taskGroup.taskGroupName, 'task', task.taskName]);
    }
  }
  handleDeleteTaskEvent(event: MouseEvent) {
    console.log("Handle global click event")
    const deleteTaskButton = document.querySelector('.btn-delete-item');
    if (deleteTaskButton && this.isDeleteTaskVisible && !deleteTaskButton.contains(event.target as Node)) {
      this.handleDeleteTask();
    }
  }

}

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTreeModule } from '@angular/material/tree';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DraftArtifactSectionComponent } from './components/draft-artifact-section/draft-artifact-section.component';
import { ArtifactSectionComponent } from './components/artifact-section/artifact-section.component';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PackagesModule } from './components/packages/packages.module';
import { LayoutComponent } from './components/layout/layout.component';
import { EditPackageHeadersComponent } from './edit-package-headers/edit-package-headers.component';
import { ArtifactApprovalComponent } from './components/artifact-approval/artifact-approval.component';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { ArtifactApprovalParentComponent } from './components/artifact-approval-parent/artifact-approval-parent.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PackageApprovalComponent } from './components/package-approval/package-approval.component';
import { PreviewComponent } from './components/preview/preview.component';
import { BtlSharedLibraryModule } from 'btl-shared-library';
import { MatPaginatorModule } from '@angular/material/paginator';
import { StylePaginatorDirective } from './directives/style-paginator.directive';
import { MatDividerModule } from '@angular/material/divider';
import { ArtifactsApprovalViewPopupComponent } from './components/artifacts-approval-view-popup/artifacts-approval-popup.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AuthService } from './services/auth.service';
import { AddArtifactDialogComponent } from './components/add-artifact-dialog/add-artifact-dialog.component';
import { HomeComponent } from './components/home/home.component';



@NgModule({
  declarations: [
    AppComponent,
    StylePaginatorDirective,
    SidebarComponent,
    DraftArtifactSectionComponent,
    ArtifactSectionComponent,
    ArtifactsApprovalViewPopupComponent,
    SpinnerOverlayComponent,
    LayoutComponent,
    EditPackageHeadersComponent,
    ArtifactSectionComponent,
    ArtifactApprovalComponent,
    ArtifactApprovalParentComponent,
    PackageApprovalComponent,
    PreviewComponent,
    UnauthorizedComponent,
    AddArtifactDialogComponent,
    HomeComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    DragDropModule,
    MatMenuModule,
    MatTreeModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatInputModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    PackagesModule,
    MatInputModule,
    MatTableModule,
    MatTabsModule,
    MatCheckboxModule,
    MatDialogModule,
    BtlSharedLibraryModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule
  ],
  providers: [AuthService, {
    provide: APP_INITIALIZER,
    useFactory: (authService: AuthService) => {
      return () => authService.getUserInfo().catch((err) => console.log(err));
    },
    deps: [AuthService],
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TrainingPackageService } from './training-package-service';
import { Task, TaskGroup } from '../models/training-package.model';
import { User } from '../models/user.model';
@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  constructor(
    private trainingPackageService: TrainingPackageService,
  ) { }
  private taskGroupsSource = new BehaviorSubject<any>(null);
  private searchFilters = new BehaviorSubject<any>(null);
  private rowData = new BehaviorSubject<any>(null);
 
  private showEditPackageHeaders = new BehaviorSubject<any>(null);
  private currentEditDraftPackage = new BehaviorSubject<any>(null);
  private selectedValues: { [key: string]: string } = {};
  private selectedValuesListSubject = new BehaviorSubject<{ type: string, value: string | null }[]>([]);
  private activateCreateButton = new BehaviorSubject<boolean>(false);
  private loadDraftPackages = new BehaviorSubject<boolean>(false);
  private currentModel = new BehaviorSubject<any>(null);
  private currentPage = new BehaviorSubject<any>(null)
  private currentTask = new BehaviorSubject<any>(null);
  private currentTaskGroup = new BehaviorSubject<any>(null);
  private user = new BehaviorSubject<any>(null);
  private allApprovedArtifacts = new BehaviorSubject<any>(null);
  private artifactsList = new BehaviorSubject<any>(null);
  private lookupData = new BehaviorSubject<any>(null);
  private editCount = new BehaviorSubject<any>(null);
  private draftPackages = new BehaviorSubject<any>(null);
  private selectAllSubject = new BehaviorSubject<boolean>(false);
  private showPreviewPackageHeaders = new BehaviorSubject<any>(null);
  public readonly DOCUMENT_EXTENSIONS = ['doc', 'docx', 'pdf'];
  public readonly VIDEO_EXTENSIONS = ['mp4', 'avi'];
  public readonly PPT_EXTENSIONS = ['ppt', 'pptx'];
  private sidebarType = new BehaviorSubject<string>('edit')



  taskGroups$ = this.taskGroupsSource.asObservable();
  searchFilters$ = this.searchFilters.asObservable();
  
  showEditPackageHeaders$ = this.showEditPackageHeaders.asObservable();
  currentEditDraftPackage$ = this.currentEditDraftPackage.asObservable();
  rowData$ = this.rowData.asObservable();
  selectedValues$ = new BehaviorSubject<{ [key: string]: string }>(this.selectedValues);
  activateCreateButton$ = this.activateCreateButton.asObservable();
  loadDraftPackages$ = this.loadDraftPackages.asObservable();
  selectedValuesList$ = this.selectedValuesListSubject.asObservable();
  currentModel$ = this.currentModel.asObservable();
  currentPage$ = this.currentPage.asObservable();
  currentTask$ = this.currentTask.asObservable();
  selectedValuesList: { type: string, value: string | null }[] = [];
  currentTaskGroup$ = this.currentTaskGroup.asObservable();
  allApprovedArtifacts$ = this.allApprovedArtifacts.asObservable();
  user$ = this.user.asObservable();
  artifactsList$ = this.artifactsList.asObservable();
  lookupData$ = this.lookupData.asObservable();
  editCount$ = this.editCount.asObservable();
  draftPackages$ = this.draftPackages.asObservable();
  selectAll$ = this.selectAllSubject.asObservable();
  showPreviewPackageHeaders$ = this.showPreviewPackageHeaders.asObservable();
  sidebarType$ = this.sidebarType.asObservable();





  resetEditCount() {
    this.editCount.next(0);
  }

  updateDraftPackages(draftPackages: any) {
    this.draftPackages.next(draftPackages)
  }

  updateEditCount(count: number) {
    this.editCount.next(count + 1)
  }

  updateLookupData(lookupData: any) {
    this.lookupData.next(lookupData);
  }

  updateArtifacts(artifacts: any) {
    this.artifactsList.next(artifacts);
  }

  updateUser(user: User): void {
    this.user.next(user);
  }

  updateTaskGroups(taskGroups: any): void {
    this.taskGroupsSource.next(taskGroups);
  }

  updateTask(task: Task): void {
    this.currentTask.next(task);
  }

  updateTaskGroup(taskGroup: TaskGroup): void {
    this.currentTaskGroup.next(taskGroup);
  }

  updateApprovedArtifacts(artifacts: any) {
    this.allApprovedArtifacts.next(artifacts);
  }

  updateSearchFilters(draftPackage: any) {
    let models = draftPackage.models.map((m: any) => m.model);
    
    let filters = {
      models: models[0],
      distribution: draftPackage.distribution,
      area: draftPackage.area,
      entity: draftPackage.airlineEntity
    }
    this.updateSelectedValue('airlineEntity', filters.entity);
    
    this.currentModel.next(filters.models);
    this.activateCreateButton.next(true);
    this.searchFilters.next(filters);
  }
  updateModelFilters(draftPackage: any) {
    
    let filters = {
      models: draftPackage.model,
      distribution: draftPackage.distribution,
      area: draftPackage.area,
      entity: draftPackage.airlineEntity
    }
    this.updateSelectedValue('airlineEntity', filters.entity);
    this.currentModel.next(draftPackage.model);
    this.activateCreateButton.next(true);
    this.searchFilters.next(filters);
    
  }
  resetSearchFilters() {
    this.searchFilters.next(this.trainingPackageService.getDummyLookUpData());
    this.activateCreateButton.next(false);
  }

  updateEditPackageHeaders(show: any): void {
    this.showEditPackageHeaders.next(show);
  }
  updatePage(value: any):void{
    this.currentPage.next(value);
  }
  updateModel(value: any):void{
    this.currentModel.next(value);
  }
  updateshowPreviewPackageHeaders(show: any): void {
    this.showPreviewPackageHeaders.next(show);
  }

  updateCurrentEditDraftPackage(draftPackage: any) {
    this.currentEditDraftPackage.next(draftPackage);
  }
  updateRowData(pkg:any){
    this.rowData.next(pkg);
  }
  updateSelectedValue(type: string, value: string | null): void {

    const sanitizedValue: string = value !== null ? value : '';
    this.selectedValues[type] = sanitizedValue;
    this.selectedValues$.next({ ...this.selectedValues });

    // Update selectedValuesList
    this.selectedValuesList = Object.entries(this.selectedValues)
      .filter(([key, value]) => value !== null && value !== undefined)
      .map(([type, value]) => ({ type, value }));

    this.selectedValuesListSubject.next(this.selectedValuesList);
    if (type === "models") {
      this.currentModel.next(value);
    }
    // Hardcoded types
    const allTypes = ['airlineEntity', 'distribution', 'models', 'area'];
    const selectionTypes = ['airlineEntity', 'distribution', 'models','area'];
    // Check if all required fields are selected
    const allFieldsSelected = allTypes.every(type => this.selectedValues[type] !== null && this.selectedValues[type] !== undefined);
    const allOptionsSelected = selectionTypes.every(type => this.selectedValues[type] !== null && this.selectedValues[type] !== undefined);

    this.activateCreateButton.next(allFieldsSelected);

    if (type == "models") {
      this.loadDraftPackages.next(allOptionsSelected);
    }

  }

  updateCurrentDraftData(draftPackage: any, currentModel: any) {
    this.currentEditDraftPackage.next(draftPackage);
    // update current task-group
    let model = draftPackage.models.filter((m: any) => m.model === currentModel);
    // update task-groups
    this.taskGroupsSource.next(model[0].taskGroups);
  }

  setSideBarType(type: string) {
    this.sidebarType.next(type);
  }

  updateActivateCreateButton(activateCreateButton: boolean) {
    this.activateCreateButton.next(activateCreateButton);
  }

  updateCurrentModel(currentModel: any) {
    this.currentModel.next(currentModel);
}
}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { TaskGroupsComponent } from './task-groups/task.groups.component';
import { TrainingPackageComponent } from './training-package/training.package.component';
import { TrainingPackageService } from 'src/app/services/training-package-service';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay-service';
import { MatTreeModule } from '@angular/material/tree';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TrainingPackageDetailsComponent } from './training-package-details/training-package-details.component';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { StylePaginatorDirective } from 'src/app/directives/style-paginator.directive';
import { MatMenuModule } from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { PaginatorDirective } from 'src/app/directives/paginator.directive';
@NgModule({
  declarations: [TaskGroupsComponent, TrainingPackageComponent,PaginatorDirective,
    TrainingPackageDetailsComponent],
  imports: [
    MatMenuModule,
    MatButtonModule,
    CommonModule,
    FormsModule,
    MatTableModule,
    HttpClientModule,
    MatPaginatorModule, 
    DragDropModule,
    MatIconModule,
    MatTreeModule,
    MatSelectModule,
    MatExpansionModule,
    MatInputModule,
    MatProgressSpinnerModule,
    RouterModule.forRoot([
      // {
      //   path: 'packages',
      //   component: TrainingPackageComponent,
      //   pathMatch: 'full',
      // },
    ]),
  ],
  providers: [TrainingPackageService, SpinnerOverlayService],
  exports: [TaskGroupsComponent, TrainingPackageComponent, TrainingPackageDetailsComponent],
})
export class PackagesModule { }

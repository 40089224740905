import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay-service';
import { ArtifactsService } from 'src/app/services/artifacts.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { saveAs as importedSaveAs } from "file-saver";
import { BtlSharedLibraryService } from 'btl-shared-library';
import { PackageUtilService } from 'src/app/services/package-util.service';
import { TaskGroup, Task, Model } from 'src/app/models/training-package.model';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  @Input() artifacts: any[] = [];
  @Input() currentEditDraftPackage: any[] = [];
  currentTaskGroups: TaskGroup[] = [];
  @ViewChildren('artifactInput') artifactInputs!: QueryList<ElementRef>;
  isTaskCompleted: boolean = false;


  constructor(private sharedDataService: SharedDataService,
    private spinnerOverlayService: SpinnerOverlayService,
    private artifactsService: ArtifactsService,
    private btlSharedLibraryService: BtlSharedLibraryService,
    private packageUtilService: PackageUtilService
  ) {
  }
  artifactMetadata: any = [
    { name: "Export Control", value: "#12345678" },
    { name: "Revision Date", value: "March 1, 2023" },
    { name: "Revision", value: "05-22-2023" }
  ]
  tables: number[] = [1];
  currentTask!: Task;
  currentModel!: Model;


  ngOnInit(): void {

    this.sharedDataService.taskGroups$.subscribe(taskGroups => {
      this.currentTaskGroups = taskGroups;
    })
    this.sharedDataService.artifactsList$.subscribe(artifacts => {
      this.artifacts = artifacts;
    })

    // Fetch metadata
    this.packageUtilService.appendMetadataToArtifact(this.artifacts, "").then(updatedArtifacts => {
      // Update artifacts with metadata
      this.artifacts = updatedArtifacts
    });

    this.sharedDataService.currentEditDraftPackage$.subscribe(currentEditDraftPackage => {
      this.currentEditDraftPackage = currentEditDraftPackage;
    })
    this.sharedDataService.currentModel$.subscribe(currentModel => {
      this.currentModel = currentModel;
    })
    if (this.artifacts && this.artifacts.length > 0) {
      this.artifacts?.forEach((artifact: any) => {
        Object.defineProperty(artifact, 'isArtifactCompleted', {
          value: false,
          writable: true,
          enumerable: true,
          configurable: true,
        });
      });
    }

    this.sharedDataService.currentTask$.subscribe(task => {
     
      this.currentTask = task;
    })

    //this.initialiazeTableSize();

  }

  getIconValueFromFilename(filename: string): string {
    const extension = filename.split('.').pop();
    if (extension) {
      const lowercaseExtension = extension.toLowerCase();
      const documentExtensions = this.sharedDataService.DOCUMENT_EXTENSIONS;
      const videoExtensions = this.sharedDataService.VIDEO_EXTENSIONS;
      const pptExtensions = this.sharedDataService.PPT_EXTENSIONS;

      if (documentExtensions.includes(lowercaseExtension)) {
        return 'description'; // It's a document
      } else if (videoExtensions.includes(lowercaseExtension)) {
        return 'movie'; // It's a video
      }
      else if (pptExtensions.includes(lowercaseExtension)) {
        return 'co_present'; // It's a presentation
      }

    }
    return 'description'; // Default
  }

  onClickDownloadArtifact(artifact: any) {

    this.spinnerOverlayService.show();
    this.artifactsService.downloadArtifact(artifact.artifactId.replace('_update_', ''), 'FTDOC')
      .subscribe({
        next: (data) => {
          if (data.type === HttpEventType.Response) {
            importedSaveAs(data.body, artifact.fileName);
            this.spinnerOverlayService.hide();
          }
        },
        error: (e) => {
          let errorMessage = "";

          e.error.text().then((text: any) => {
            try {
              errorMessage = text;


            } catch (jsonError) {
            } finally {
              this.btlSharedLibraryService.updateStatusMessage(true, errorMessage, false);
              window.scroll(0, 0);
              this.spinnerOverlayService.hide();
            }
          })
        }
      })
  }
  markComplete(artifact: any) {
    artifact.isArtifactCompleted = !artifact.isArtifactCompleted;

  }

}

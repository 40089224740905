// training-package.model.ts

export interface Artifact {
    artifactId: string;
    title: string;
    description: string;
    fileName: string;
    version: string;
    createdBy: string;
    modifiedBy: string | null;
    approvalStatus: ApprovalStatus | null;
    createdAt: string | null;
    modifiedAt: string | null;
    delete: boolean;
    selected: boolean;
    fresh:boolean;
}

export interface Task {
    taskId?: string;
    taskOrder: number;
    taskName: string;
    taskDescription?: string;
    artifacts?: any[];
    taskState?: string;
    artifactOrder?: string[];
    status?:string;
    action?:string;

}



export interface TaskGroup {
    taskGroupId?: string;
    taskGroupName?: string;
    taskGroupOrder?: number;
    action?:string;
    status?:string;
    tasks?: Task[];
}

export interface Model {
    model: string;
    taskGroups?: TaskGroup[];
    updated : boolean;
}

export interface DraftTrainingPackage {
    trainingPackageId: string;
    airlineEntity: string;
    distribution: string;
    area: string;
    title: string;
    createdBy: string;
    modifiedBy: string | null;
    models?: Model[];
}

export interface ApprovalTrainingPackage {
    trainingPackageId: string;
    airlineEntity: string;
    distribution: string;
    area: string;
    title: string;
    createdBy: string;
    modifiedBy: string | null;
    models?: Model[];
    selected: boolean;
}

export interface ApprovalStatus {
    objectId: string;
    objectType: string;
    status: string;
    approver: string;
    requester: string;
    createdAt: string;
    modifiedAt: string | null;
}

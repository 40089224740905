<div cdkDropListGroup>
    <div class="example-container" cdkDropList [cdkDropListData]="taskGroups" class="example-list" *ngIf="taskGroups"
        (cdkDropListDropped)="dropGroup($event)">
        <mat-accordion appearance="fill" class="example-box"
            *ngFor="let taskgroup of taskGroups; let groupIndex = index" cdkDrag>
            <mat-expansion-panel class="mat-elevation-z0">

                <mat-expansion-panel-header class="custom-header">
                    <mat-panel-title [ngClass]="{'m-1 ml-0 mr-0':selectedTaskGroupId === taskgroup?.taskGroupId}">
                        <mat-form-field appearance="fill" class="form-input" *ngIf="!taskgroup.action"
                            [ngClass]="selectedTaskGroupId === taskgroup?.taskGroupId ? 'editable-taskgroup-field' : 'noneditable-taskgroup-field'">
                            <mat-icon class="horizontal-lines-icon">drag_handle</mat-icon>
                            <input matInput [value]="taskgroup.taskGroupName" class="editable-task-input p-1"
                                [(ngModel)]="taskgroup.taskGroupName"
                                [readonly]="selectedTaskGroupId !== taskgroup?.taskGroupId"
                                (blur)="onInputBlur('taskGroup',taskgroup,groupIndex)"
                                (keyup.enter)="onInputEnter('taskGroup',taskgroup,groupIndex)"
                                (click)="selectedTaskGroupId != '' && $event.stopImmediatePropagation()"
                                (keydown.Space)="$event.stopImmediatePropagation()" />
                            <button mat-icon-button [matMenuTriggerFor]="kebabTaskGroupMenu"
                                (click)="$event.stopImmediatePropagation()" class="float-end">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-menu #kebabTaskGroupMenu="matMenu">
                            <button mat-menu-item (click)="deleteTaskGroup($event, groupIndex, taskgroup)">
                                <mat-icon>delete</mat-icon>
                                <span>Delete</span>
                            </button>
                            <button mat-menu-item (click)="renameTaskGroup(taskgroup)">
                                <mat-icon>edit</mat-icon>
                                <span>Rename</span>
                            </button>
                        </mat-menu>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div cdkDropList [cdkDropListData]="taskgroup.tasks" class="example-list task-list"
                    (cdkDropListDropped)="drop($event,taskgroup)">
                    <div class="example-box task-name" (click)="openTaskView(taskgroup,task)"
                        *ngFor="let task of taskgroup?.tasks ; let taskIndex = index; " cdkDrag>
                        <mat-form-field appearance="fill" class="form-input" *ngIf="!task.action"
                            [ngClass]="selectedTaskId === task?.taskId ? 'editable-task-field' : 'noneditable-task-field'">
                            <mat-icon class="horizontal-lines-task-icon pt-0">drag_handle</mat-icon>
                            <input matInput value="CBTA Training" class="editable-task-input p-1"
                                [(ngModel)]="task.taskName" [readonly]="selectedTaskId !== task.taskId"
                                (blur)="updateTask()" (keyup.enter)="updateTask()"
                                (click)="selectedTaskId != '' && $event.stopImmediatePropagation()" />
                            <button mat-icon-button [matMenuTriggerFor]="kebabMenu"
                                (click)="$event.stopImmediatePropagation()" class="float-end">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-menu #kebabMenu="matMenu">
                            <button mat-menu-item (click)="deleteTask($event, taskgroup, task, taskIndex, groupIndex)">
                                <mat-icon>delete</mat-icon>
                                <span>Delete</span>
                            </button>
                            <button mat-menu-item (click)="renameTask(task)">
                                <mat-icon>edit</mat-icon>
                                <span>Rename</span>
                            </button>
                        </mat-menu>
                    </div>
                    <p class="input-field-task input-field example-box" *ngIf="isTaskInputVisible[groupIndex]">
                        <mat-form-field appearance="fill" class="form-input">
                            <mat-label class="input-label">Task Name</mat-label>
                            <input matInput placeholder="Task Name" value="Class 1" [(ngModel)]="taskName"
                                (keyup.enter)="onInputEnter('task',taskgroup,groupIndex)"
                                (blur)="onInputBlur('task',taskgroup,groupIndex)">
                        </mat-form-field>
                    </p>
                    <button class="add-task" (click)="addNewTask(groupIndex)">+ Add Task</button>
                </div>
            </mat-expansion-panel>



            <div class="divider-1"></div>

        </mat-accordion>
    </div>
    <p class="input-field example-box" *ngIf="isTaskGroupInputVisible">
        <mat-form-field appearance="fill" class="form-input">
            <mat-label class="input-label">Task Group Name</mat-label>
            <input matInput placeholder="TaskGroup Name" value="CBTA Training" [(ngModel)]="taskGroupName"
                (keyup.enter)="onInputEnter('taskGroup',null,null)" (blur)="onInputBlur('taskGroup',null,null)"
                #taskGroupInput>
        </mat-form-field>
    </p>
    <button class="add-group" [hidden]="(isCreateDisabled$ | async) !== true" (click)="addTaskGroup()">+ Add
        Group</button>
</div>
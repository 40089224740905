import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';
import { Constants } from "src/app/utils/Constants"
@Injectable({
  providedIn: 'root',
})
export class TrainingPackageService {
  entityOptions: any[] = [
    {
      "airlineCode": "AA2",
      "airlineName": "AA Test"
    },
    {
      "airlineCode": "AAL",
      "airlineName": "American Airlines"
    },
    {
      "airlineCode": "ABD",
      "airlineName": "Presidential Flight"
    },
    {
      "airlineCode": "ABM",
      "airlineName": "Air Bridge Cargo"
    }
  ]
  //entityOptions: string[] = ['GHT', 'Alaska Airlines', 'Akasa Airline', 'TBC Airline'];
  distributionOptions: string[] = ['Direct', 'Partner'];
  //modelOptions: string[] = ['737-Max', '787', '747-Max'];
  modelOptions: any[] = [
    {
      "modelName": "707"
    },
    {
      "modelName": "737-Max",
    },
    {
      "modelName": "787",
    },
    {
      "modelName": "747-Max"
    }
  ]
  areaOptions: string[] = ['Flight', 'Maintenance'];

  private httpHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Ocp-Apim-Subscription-Key', environment.btlTpSubscriptionKey);

  constructor(
    private http: HttpClient) { }

  getTpLookUpValues(): Observable<any> {
    return this.http.get<any>(`${environment.backendBaseUrl}/lookup`, {
      headers: this.httpHeaders,
    });
  }

  getDraftTrainingPackages(filterJson:any, pageNumber: number, pageSize: number): Observable<any> {
    /**
     * TODO: add filter userId 
       /training-package?filterSearchJson=%7B%22createdBy%22%3A%22<f_name>.<l_name>%40boeing.com%22%7D%7D&packageType=draft
     */

    let params: HttpParams = new HttpParams();
    params = params.set('filterSearchJson', JSON.stringify(filterJson));
    params = params.set('size', pageSize);
    params = params.set('page', pageNumber);

    return this.http.get<any>(`${environment.backendBaseUrl}/training-package?packageType=draft`, {
      headers: this.httpHeaders,
      params,
    });
  }

  getApprovalTrainingPackages(userId: string, page: number, size: number): Observable<any> {
    /**
     * TODO: add filter userId 
       /training-package?filterSearchJson=%7B%22createdBy%22%3A%22<f_name>.<l_name>%40boeing.com%22%7D%7D&packageType=draft
     */

    let params: HttpParams = new HttpParams();
    params = params.set('size', size);
    params = params.set('page', page);
    // training-package?packageType=approval
    return this.http.get<any>(`${environment.backendBaseUrl}/training-package/approval/packages`, {
      headers: this.httpHeaders,
      params,
    });
  }

  getApprovedTrainingPackages(filterJson:any, pageNumber: number, pageSize: number): Observable<any> {
    if (filterJson && filterJson.createdBy) {
      delete filterJson.createdBy;
    }
    let params: HttpParams = new HttpParams();
    params = params.set('filterSearchJson', JSON.stringify(filterJson));
    params = params.set('size', pageSize);
    params = params.set('page', pageNumber);

    return this.http.get<any>(`${environment.backendBaseUrl}/training-package?packageType=approved`, {
      headers: this.httpHeaders,
      params,
    });
  }

  approveTrainingPackage(requestBody: any, userId: string): Observable<any> {
    // /training-package/process-approval?action=approve&approver
    // training-package/process-approval?action={action}&approver={approver}
    return this.http.post<any>(`${environment.backendBaseUrl}/training-package/process-approval?action=approve&approver=${userId}`,
      requestBody, {
      headers: this.httpHeaders
    });
  }

  rejectTrainingPackage(requestBody: any, userId: string): Observable<any> {
    return this.http.post<any>(`${environment.backendBaseUrl}/training-package/process-approval?action=reject&approver=${userId}`,
      requestBody, {
      headers: this.httpHeaders
    });
  }
  createDraftTrainingPackage(trainingPackage: any): Observable<any> {
    return this.http.post<any>(`${environment.backendBaseUrl}/training-package/draft`,
      trainingPackage, {
      headers: this.httpHeaders
    });
  }

  saveDraftTrainingPackage(trainingPackage: any, userId: string): Observable<any> {
    trainingPackage.modifiedBy = userId;
    trainingPackage = this.cleanDraftTrainingPackage(trainingPackage);

    return this.http.put<any>(`${environment.backendBaseUrl}/training-package/draft`,
      trainingPackage, {
      headers: this.httpHeaders
    });
  }

  submitDraftTrainingPackageForApproval(trainingPackageId: string, requester: string): Observable<any> {

    return this.http.post<any>(`${environment.backendBaseUrl}/training-package/submit/approval?trainingPackageId=${trainingPackageId}&requester=${requester}`,
      {},
      { headers: this.httpHeaders }
    );
  }

  getDummyLookUpData() {
    return {
      status: '200 OK',
      message: 'Lookup data Fetched Successfully',
      data: {
        entities: this.entityOptions,
        distribution: this.distributionOptions,
        models: this.modelOptions,
        area: this.areaOptions,
      },
    };
  }
  getDummyUserData() {
    let user: User = {
      getStartedDismissed: false,
      unreadNotification: 0,
      bemsId: '3232436',
      email: 'saurabh.bharti@boeing.com',
      firstName: 'Saurabh',
      lastName: 'Bharti',
      airlineCode: 'TBC',
      airlineName: 'The Boeing Company'
    }
    return user;
  }
  cleanDraftTrainingPackage(draftPackage: any): any {
    // Create a deep copy of the draftPackage
    const cleanCopy = JSON.parse(JSON.stringify(draftPackage));

    // Remove unwanted properties
    delete cleanCopy.approvalStatus;
    delete cleanCopy.createdAt;
    delete cleanCopy.modifiedAt;
    delete cleanCopy.delete;

    // Update the artifacts property to an array of artifact ids
    if (cleanCopy.models) {
      cleanCopy.models.forEach((model: any) => {
        if (model.taskGroups) {
          model.taskGroups.forEach((taskGroup: any) => {
            if (taskGroup.tasks) {
              taskGroup.tasks.forEach((task: any) => {
                if (task.status === 'new') {
                  delete task.taskId;
                  delete task.status;
              }
                if (task.artifacts) {
                  task.artifacts = task.artifacts.map((artifact: any) => ({
                    artifactId: artifact.artifactId,
                    title: artifact.title,
                    description: artifact.description,
                    action: artifact.action != null ? artifact.action : "NO CHANGE",
                    fileName: artifact.fileName,
                    fresh: artifact.fresh,
                    artifactOrder: artifact.artifactOrder
                  }));
                }
              });
            }
          });
        }
      });
    }
    return cleanCopy;
  }
}

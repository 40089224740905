<!-- <div class="container-fluid app-container px-0 d-flex justify-content-start"> -->
<div class="left-sidebar-col" [ngStyle]="{'height': '100vh'}">
    <div class="left-sidebar">

        <div class="left-profile">
            <!-- <div class="left-side" (click)="showNotification()"> -->
            <div class="left-side" >
                <img class="profile-pic" src="assets/profile-pic.png">
                <!-- <div class="unread-count" *ngIf="user && user.unreadNotification>0">{{user.unreadNotification}}</div> -->
            </div>
            <div class="right-side">
                <div class="welcome-back">Welcome Back,</div>
                <div class="boeing-editor bold-text">BOEING EDITOR</div>
                <div class="admin orange-text">ADMIN</div>
                <!-- <div class="username" *ngIf="user">{{user.airlineName}}</div> -->
            </div>
        </div>
        <div>
            <mat-form-field appearance="fill" class="model-dropdown btl-dd">
                <mat-label class="label-color">Entity</mat-label>
                <mat-select [(ngModel)]="selectedEntity" (ngModelChange)="onFieldChange('airlineEntity')" [disabled]="sidebarType === 'preview'">
                    <mat-option *ngFor="let entity of entityOptions" [value]="entity.airlineCode">{{entity.airlineName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="model-dropdown btl-dd">
                <mat-label class="label-color">Distribution</mat-label>
                <mat-select [(ngModel)]="selectedDistribution" (ngModelChange)="onFieldChange('distribution')" [disabled]="sidebarType === 'preview'">
                    <mat-option *ngFor="let distribution of distributionOptions" [value]="distribution">{{distribution}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="model-dropdown btl-dd">
                <mat-label class="label-color">Area</mat-label>
                <mat-select [(ngModel)]="selectedArea" (ngModelChange)="onFieldChange('area')" [disabled]="sidebarType === 'preview'">
                    <mat-option *ngFor="let area of areaOptions" [value]="area">{{area}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="model-dropdown btl-dd">
                <mat-label class="label-color">Model</mat-label>
                <mat-select [(ngModel)]="selectedModel" (ngModelChange)="onFieldChange('models')" [disabled]="sidebarType === 'preview'">
                    <mat-option *ngFor="let model of modelOptions" [value]="model">{{model}}</mat-option>
                </mat-select>
            </mat-form-field>


            <div class="divider-1"></div>


            <!-- <div cdkDropListGroup>
            <div class="example-container" cdkDropList [cdkDropListData]="taskGroups" class="example-list"
                (cdkDropListDropped)="dropGroup($event)">
                <mat-accordion appearance="fill" class="example-box" *ngFor="let taskgroup of taskGroups; let i=index"
                    cdkDrag>
                    <mat-expansion-panel class="mat-elevation-z0">
                        <mat-expansion-panel-header class="custom-header">
                            <mat-panel-title>
                                <mat-icon class="horizontal-lines-icon">drag_handle</mat-icon>
                                <div class="welcome-back">{{ taskgroup.title }}</div>
                            </mat-panel-title>


                        </mat-expansion-panel-header>

                        <div cdkDropList [cdkDropListData]="taskgroup.tasks" class="example-list"
                            (cdkDropListDropped)="drop($event)">
                            <div class="example-box" *ngFor="let item of taskgroup?.tasks" cdkDrag>{{item}}</div>

                            <button class="add-task">+ Add Task</button>

                        </div>
                    </mat-expansion-panel>
                    <div class="divider-1"></div>
                </mat-accordion>
                <button class="add-group">+ Add Group</button>
            </div>
         </div> -->
            <app-task-groups [taskGroups]="taskGroups" [model]="selectedModel" *ngIf="(sidebarType === 'edit')">
            </app-task-groups>

            <div *ngIf="(sidebarType === 'preview')">


                <div *ngIf="(sidebarType === 'preview')">
                    <mat-accordion appearance="fill" class="example-box"
                        *ngFor="let taskgroup of taskGroups; let i=index" >
                        <mat-expansion-panel class="mat-elevation-z0" [expanded]="i === 0">
                            <mat-expansion-panel-header class="custom-header">
                                <mat-panel-title>
                                    <div class="welcome-back">
                                        {{ taskgroup.taskGroupName}}
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="example-box task-name" *ngFor="let task of taskgroup?.tasks let j=index">
                                <label>
                                    <input type="radio" name="selectedTask" [checked]=" i === 0 && j === 0" (click)="openTaskView(taskgroup,task)"
                                        [value]="task" />
                                    {{ task.taskName }}
                                </label>
                            </div>
                        </mat-expansion-panel>


                    </mat-accordion>

                </div>

            </div>
        </div>


    </div>
</div>
<!-- </div> -->
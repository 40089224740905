import { Component, OnInit } from '@angular/core';
import { SharedDataService } from '../services/shared-data.service';
import { TrainingPackageService } from '../services/training-package-service';
import { SpinnerOverlayService } from '../services/spinner-overlay-service';
import { TaskGroup } from '../models/training-package.model';
import { User } from '../models/user.model';
import { PackageUtilService } from '../services/package-util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BtlSharedLibraryService } from 'btl-shared-library';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-edit-package-headers',
  templateUrl: './edit-package-headers.component.html',
  styleUrls: ['./edit-package-headers.component.scss']
})
export class EditPackageHeadersComponent implements OnInit {
  showEditPackageHeaders: boolean = false;
  showPreviewPackageHeaders: boolean = false;
  currentEditDraftPackage: any = null;
  response: any;
  model: any;
  currentTaskGroups: any;
  user: User | null;
  editCount: number = 0;
  currentPage:any = 'editor';

  constructor(
    private sharedDataService: SharedDataService,
    private trainingPackageService: TrainingPackageService,
    private snipperOverlayService: SpinnerOverlayService,
    private packageUtilService: PackageUtilService,
    private btlSharedLibraryService: BtlSharedLibraryService,
    private sharedService: SharedService,
    private router: Router,
  ) {
    this.user = null;
  }

  ngOnInit(): void {
    this.sharedDataService.updatePage("editor");
    // subscribe to edit count
    this.sharedDataService.editCount$.subscribe(count => {
      if (count === null) { this.editCount = 0 } else {
          this.editCount = count;  
          // Adding  updated to true for modified model
          this.currentEditDraftPackage?.models.forEach((item : any) => {
            if (item!=null && item.model == this.model){
              item.updated = true; 
            }
          })
         }
    });


    // get current draft package being edited
    this.sharedDataService.currentEditDraftPackage$.subscribe((draftPackage) => {
      this.currentEditDraftPackage = draftPackage;
    });

    // get show headers condition
    this.sharedDataService.showEditPackageHeaders$.subscribe((showEditPackageHeaders) => {
      this.showEditPackageHeaders = showEditPackageHeaders;
    });

    this.sharedDataService.showPreviewPackageHeaders$.subscribe((showPreviewPackageHeaders) => {
      this.showPreviewPackageHeaders = showPreviewPackageHeaders;
    });

    // get lastest data [model,task-groups]
    this.sharedDataService.currentModel$.subscribe(model => {
      this.model = model;
    })

    // subscribe to current taskgroup
    this.sharedDataService.taskGroups$.subscribe(taskGroups => {
      this.currentTaskGroups = taskGroups;
    })

    // assign current user
    this.user = this.sharedService.getUserInfo();
    this.sharedDataService.currentPage$.subscribe((value)=>{

      this.currentPage = value;
    });
  }
  navigateToApprovals(){
    this.sharedDataService.updateshowPreviewPackageHeaders(false);
    this.router.navigate(["/approvals"]);
  }
  submitPackageForApproval() {
    this.snipperOverlayService.show();

    let requester = '';
    if (this.user != null) {
      requester = this.user.email;
    }
    // validate request [Artifact should have a title]
    // let {isDraftValid,artifacts} = this.packageUtilService.validateSaveDraftPackage(this.currentEditDraftPackage);

    // if(!isDraftValid){
    //   this.sharedDataService.updateStatusMessage(true,`Please add title to the Artifact ${artifacts}`,false)
    // }

    this.trainingPackageService
      .submitDraftTrainingPackageForApproval(this.currentEditDraftPackage.trainingPackageId, requester)
      .subscribe({
        next: (data) => {
          if (data.status === '200 OK') {
            this.snipperOverlayService.hide();
            this.btlSharedLibraryService.updateStatusMessage(true, `${data.message} by ${this.user?.firstName} ${this.user?.lastName}`, true)

          }
        },
        error: (e) => {
          console.error(e);
          this.snipperOverlayService.hide();
          const errorMessage = e.error && e.error.message ? e.error.message : "An error occurred during the request.";
          this.btlSharedLibraryService.updateStatusMessage(true, errorMessage, false);
        }
      })
  }
  savePackage() {
    this.sharedDataService.resetEditCount();
    this.snipperOverlayService.show();
    
    // Adding  updated to true for modified model
    this.currentEditDraftPackage.models.forEach((item : any) => {
      if (item!=null && item.model == this.model){
        item.updated = true;
      }
    }) 

    // determine if it's an update or create a new package
    if (this.currentEditDraftPackage.trainingPackageId.includes('new') || this.currentEditDraftPackage.trainingPackageId.includes('APPROVED'))
    {
      // create new

      this.trainingPackageService.createDraftTrainingPackage(this.currentEditDraftPackage).subscribe({
        next: (data) => {
          this.response = data.data;
          if (data.status === '201 CREATED') {
            this.snipperOverlayService.hide();
            this.btlSharedLibraryService.updateStatusMessage(true, data.message, true);
            this.sharedDataService.updateCurrentDraftData(data.data, this.model)
          }
        },
        error: (e) => {
          console.error(e);
          this.snipperOverlayService.hide();
          const errorMessage = e.error && e.error.message ? e.error.message : "An error occurred during the request.";
          this.btlSharedLibraryService.updateStatusMessage(true, errorMessage, false);
        }
      })
    } else {
      // update

      this.snipperOverlayService.show();

      this.trainingPackageService.saveDraftTrainingPackage(this.currentEditDraftPackage, this.user!.email).subscribe({
        next: (data) => {

          this.response = data.data;

          if (data.status === '200 OK') {
            this.snipperOverlayService.hide();
            this.btlSharedLibraryService.updateStatusMessage(true, data.message, true);
            this.sharedDataService.updateCurrentDraftData(data.data, this.model)
          }
        },
        error: (e) => {
          console.error(e);
          this.snipperOverlayService.hide();
          const errorMessage = e.error && e.error.message ? e.error.message : "An error occurred during the request.";
          this.btlSharedLibraryService.updateStatusMessage(true, errorMessage, false);
        },
      });
    }
  }



  showPreview() {
    this.sharedDataService.currentEditDraftPackage$.subscribe((draftPackage) => {
      this.currentEditDraftPackage = draftPackage;
    });
    let taskGroup = this.currentTaskGroups[0];
    let task = taskGroup.tasks[0]
    this.sharedDataService.updateTask(task);
    // this.router.navigate(['preview'])
    this.router.navigate(['packages',this.currentEditDraftPackage.trainingPackageId, 'taskGroup', taskGroup.taskGroupName, 'task', task.taskName, 'preview']);
    this.sharedDataService.updateEditPackageHeaders(false);
    this.sharedDataService.updateshowPreviewPackageHeaders(true);
    this.sharedDataService.setSideBarType('preview');
  }

  backToEditMode() {
    this.sharedDataService.currentEditDraftPackage$.subscribe((draftPackage) => {
      this.currentEditDraftPackage = draftPackage;
    });
    let taskGroup = this.currentTaskGroups[0];
    let task = taskGroup.tasks[0]
    this.sharedDataService.updateTask(task);
    // this.router.navigate(['preview'])
    this.router.navigate(['packages',this.currentEditDraftPackage.trainingPackageId, 'taskGroup', taskGroup.taskGroupName, 'task', task.taskName]);
    this.sharedDataService.updateEditPackageHeaders(true);
    this.sharedDataService.updateshowPreviewPackageHeaders(false);
    this.sharedDataService.setSideBarType('edit');
  }

}

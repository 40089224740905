import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,) { }

  ngOnInit(): void {
    this.dialogRef.backdropClick().subscribe(() => {
      this.cancel();
    });
  }
  cancel() {
    // this.dialogRef.close();
    this.dialogRef.close({ result: "cancel" });

  }
  proceed(){
    console.log("inside proceed")
    this.dialogRef.close({ result: "proceed" });
  }
}

<div class="full-height">
  <nav class="navbar navbar-dark bg-dark">
    <a href="#" class="navbar-brand">Boeing Training Library</a>

    <ul class="main-nav navbar-nav d-flex flex-row ml-auto nav-custom">
      <li class="nav-item nav-link text-white">
        <i class="material-icons">apps</i>
      </li>
      <li class="nav-item nav-link text-white">
        <i class="material-icons" [matMenuTriggerFor]="profileMenu">account_circle</i>

        <mat-menu #profileMenu="matMenu" class="profile-menu">
          <div class="profile-info">
            <div class="full-name">{{user?.firstName}} {{user?.lastName}}</div>
            <div class="email">{{user?.email}}</div>
            <div class="airline">{{user?.airlineName}}</div>
          </div>

          <!-- <button class="menu-btn-white" (click)="logout()" mat-menu-item>Your MBF Account Details</button> -->
          <button class="menu-btn-primary" (click)="logout()" mat-menu-item>LOGOUT</button>
        </mat-menu>
      </li>
      </ul>
  </nav>
  <app-edit-package-headers></app-edit-package-headers>
  <!-- <div class="app-container d-flex">
    <div class="sidebar-div">
      <app-sidebar></app-sidebar>
    </div>
    <div class="router-div"> -->
      <!-- <app-draft-artifact-section></app-draft-artifact-section>
      <app-artifact-section></app-artifact-section> -->
      <!-- <app-training-package></app-training-package> -->
      <!-- <router-outlet></router-outlet> -->
    <!-- </div> -->

    <div class="router-div">
      <router-outlet></router-outlet>
    </div>
  </div>
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChildren, ElementRef, QueryList, SimpleChanges } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay-service';
import { ArtifactsService } from 'src/app/services/artifacts.service';
import { saveAs as importedSaveAs } from "file-saver";
import { BtlSharedLibraryService } from 'btl-shared-library';
import { PackageUtilService } from 'src/app/services/package-util.service';
@Component({
  selector: 'app-artifact-section',
  templateUrl: './artifact-section.component.html',
  styleUrls: ['./artifact-section.component.scss']
})
export class ArtifactSectionComponent implements OnInit {
  @Input() artifacts: any[] = [];
  @Output() artifactUpdateEvent = new EventEmitter<any>();
  @Output() reOrderArtifactEvent = new EventEmitter<any>();
  @Output() deleteArtifactEvent = new EventEmitter<any>();
  @ViewChildren('artifactInput') artifactInputs!: QueryList<ElementRef>;
  // selectedRevision: string | undefined;
  selectedRevisions: any;
  revisions: string[] = [];
  allApprovedArtifacts:any[]=[];
  editCount: number = 0;
  isInputFocused = false;



  constructor(
    private sharedDataService: SharedDataService,
    private spinnerOverlayService: SpinnerOverlayService,
    private btlSharedLibraryService: BtlSharedLibraryService,
    private artifactsService: ArtifactsService,
    private packageUtilService:PackageUtilService
  ) {

  }

  artifactMetadata: any = [
    { name: "Course Type", value: "Differences" },
    { name: "Updated", value: "March 1, 2023" },
    { name: "Regulator", value: "FAA" },
    { name: "Document Type", value: "BoM" },
  ]
  isDeleteTaskVisible = false;
  selectedIndex: number | null = null;

  trackByArtifact(index: number,artifact: any): string {
    if(artifact != undefined) {
      return artifact.artifactId;
    }
    else {
      return ''
    }
  }

  tables: number[] = [1];
  descriptionValue: string = "";
  maxCharCount: number = 300;
  deleteArtifactVisibility: boolean[] = [];
  artifactTitleError: boolean = false;
  artifactVersionsList: any[] = [];

  ngOnInit(): void {

    console.log("Selected Artifact revisions",this.selectedRevisions);

    // subscribe to edit count
    this.sharedDataService.editCount$.subscribe(count => {
      this.editCount = count;
    })

    this.sharedDataService.allApprovedArtifacts$.subscribe(allApprovedArtifacts => {
      this.allApprovedArtifacts = allApprovedArtifacts;
    })

    this.sharedDataService.artifactsList$.subscribe(artifacts => {
      this.artifacts = artifacts;
      this.deleteArtifactVisibility = new Array(this.artifacts?.length).fill(false);
      this.updateMetadata();
      if (Array.isArray(this.artifacts)) {
        this.selectedRevisions = this.artifacts.map(artifact => {
          // Splitting the artifactId string by ':' to get the version part
          const artifactIdParts = artifact.artifactId.split(':');
          console.log(artifactIdParts[1])
          // Check if the artifactId contains "IN_WORK"
          if (artifactIdParts[1].includes('IN_WORK')) {
            // If it contains "IN_WORK", split by '_' to get the version part
            const versionParts = artifactIdParts[1].split('_');
            // The version is the first part of the split
            const version = versionParts[0];
            // Returning the version
            return version;
          } else {
            // If it doesn't contain "IN_WORK", the version is the entire artifactIdParts
            const version = artifactIdParts[1];
            // Returning the version
            return version;
          }
        });
      }
    });
  }

  updateMetadata(){
    // Fetch metadata
    this.packageUtilService.appendMetadataToArtifact(this.artifacts,'').then(updatedArtifacts => {
      // Update artifacts with metadata
      this.artifacts = updatedArtifacts
    });
  }

  initialiazeTableSize() {
    if (Array.isArray(this.artifacts)) {
      this.tables = Array.from({ length: this.artifacts.length }, (_, index) => index + 1);
    }
  }


  drop(event: CdkDragDrop<string[]>) {
    console.log("Artifacts re-order Drop Event::",event.container)
    if (event.previousContainer === event.container) {
      console.log("Move item in array:",event.container.data)
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.reOrderArtifactEvent.emit(event.container.data);
    }
  }


  onArtifactUpdate(artifact: any, action: string) {
    this.isInputFocused = false;
    this.sharedDataService.updateEditCount(this.editCount);
    console.log("On Artifact change:",artifact,action)
    if (action === 'update') {
      const status = ['NEW', 'APPROVED']
      // only update to true when artifactId has _update_ keyword
      if(status.includes(artifact.artifactStatus) || status.includes(artifact.artifactStatus.status) || artifact.artifactId.includes('_IN_WORK_')) {
        artifact.action = 'update';
        this.artifactUpdateEvent.emit({ updatedArtifact: artifact, action: 'update' });
      }
      this.artifactTitleError = !artifact.title;
      console.log("Artifact title error:",this.artifactTitleError,artifact.title)
    } else if (action === 'delete') {
      artifact.action = 'delete';
      this.artifactUpdateEvent.emit({ updatedArtifact: artifact, action: 'delete' });
    }
    else if (action === 'revision delete') {
      this.artifactUpdateEvent.emit({ updatedArtifact: artifact, action: 'revision delete' });
    }
  }

  removeAfterLastPattern(str:string, substring:string) {
    let index = str.lastIndexOf(substring);
    if (index !== -1) {
        return str.substring(0, index);
    }
    return str;
  }

  onClickDownloadArtifact(artifact: any) {
    console.log("Download:artifact:",artifact);
    this.spinnerOverlayService.show();

    let modifiedArtifactId = artifact.artifactId;
    if(artifact.artifactId.includes('_APPROVAL_')){
      modifiedArtifactId= this.removeAfterLastPattern(artifact.artifactId, '_APPROVAL_');
    }else if(artifact.artifactId.includes('_IN_WORK_')){
      modifiedArtifactId= this.removeAfterLastPattern(artifact.artifactId, '_IN_WORK_');
    }
    console.log("Downloading artifact :=>",modifiedArtifactId);
    
    this.artifactsService.downloadArtifact(modifiedArtifactId, 'FTDOC')
      .subscribe({
        next: (data) => {
          if (data.type === HttpEventType.Response) {
            importedSaveAs(data.body, artifact.fileName);
            this.spinnerOverlayService.hide();
          }
        },
        error: (e) => {
          let errorMessage = "";
          
          e.error.text().then((text: any) => {
            try {
              errorMessage = text;
              console.log("TExt error::",text)

            } catch (jsonError) {
            } finally {
              this.btlSharedLibraryService.updateStatusMessage(true, errorMessage, false);
              window.scroll(0, 0);
              this.spinnerOverlayService.hide();
            }
          })
        }
      })
  }

  handleDeleteArtifact(index: number) {
    this.selectedIndex = index;
    //this.isDeleteTaskVisible = !this.isDeleteTaskVisible;
    this.deleteArtifactVisibility[index] = !this.deleteArtifactVisibility[index];
  }

  deleteArtifact(artifact: any, index: number) {
    this.onArtifactUpdate(artifact, 'delete')
    this.handleDeleteArtifact(index);
  }


  getRevisionsForArtifact(artifact: any): any[] {
    
    if(!artifact || !this.allApprovedArtifacts) {
      return [];
    }
      const matchingApprovedArtifacts = this.allApprovedArtifacts.filter(approvedArtifact => approvedArtifact.artifactId.split(':')[0] === artifact.artifactId.split(':')[0]);

      this.artifactVersionsList = matchingApprovedArtifacts;
      return matchingApprovedArtifacts;
  }  

  updateArtifactRevision(artifact: any, selectedRevision: any,i:any) {

    this.sharedDataService.updateEditCount(this.editCount);

    this.onArtifactUpdate(artifact,'delete')
    let selectedRevisionArtifact = this.artifactVersionsList.filter(v => v.artifactId.split(":")[1] === selectedRevision);
    let currentRevision;

    if(selectedRevisionArtifact.length > 0){
      currentRevision = selectedRevisionArtifact[0];
      currentRevision.artifactStatus = "NEW";
      currentRevision.action = "update";
      currentRevision.title= artifact.title;
      currentRevision.description= artifact.description;

    }

    this.onArtifactUpdate(currentRevision,'revision delete')
    this.selectedRevisions[i]=selectedRevision;
    }
    
    onInputFocus(): void {
      this.isInputFocused = true;
    }
  
    onInputBlur(): void {
      this.isInputFocused = false;
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SharedService } from './shared.service';
import { CheckAccessModel } from '../models/check-access.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private sharedService: SharedService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.sharedService.getUserInfo() && this.sharedService.getUserInfo().firstName !== '') {
      if (this.sharedService.isAdmin() || (this.sharedService.isEditor() && this.sharedService.isApprover())) {
        if (  route.routeConfig?.path == ""){
        this.router.navigate(["packages"])
        return true;
        }
        return ( route.routeConfig?.path == "approvals" || route.routeConfig?.path == "packages" || route.routeConfig?.path == "")
      }
      if (this.sharedService.isOnlyApprover()) {
        if (route.routeConfig?.path == "") {
          this.router.navigate(["approvals"]);
          return false;
        }
        if (route.routeConfig?.path == "packages"){
          this.router.navigate(["unauthorized"]);
          return false;
        }
        return route.routeConfig?.path == "approvals";
      }
      if (this.sharedService.isOnlyEditor()) {
        if (route.routeConfig?.path == "") {
          this.router.navigate(["packages"]);
          return false;
        }
        if (route.routeConfig?.path == "approvals") {
          this.router.navigate(["unauthorized"]);
          return false;
        }
        return route.routeConfig?.path == "packages";
      }
      if (!this.sharedService.isEditor() && !this.sharedService.isApprover() && !this.sharedService.isAdmin()) {
        this.router.navigate(["unauthorized"]);
        return false;
      }
      return false;
    }
    else {
      this.router.navigate(["unauthorized"]);
      return false;
    }

    
}
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { BtlSharedLibraryService } from 'btl-shared-library';
import { PackageUtilService } from 'src/app/services/package-util.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { TrainingPackageService } from 'src/app/services/training-package-service';
import { SpinnerOverlayService } from '../../../services/spinner-overlay-service';
import { SharedService } from 'src/app/services/shared.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { PaginatorDirective } from 'src/app/directives/paginator.directive';
import { count } from 'rxjs';


@Component({
  selector: 'app-training-package',
  templateUrl: './training.package.component.html',
  styleUrls: ['./training.package.component.scss'],

})
export class TrainingPackageComponent implements OnInit {
  constructor(
    private trainingPackageService: TrainingPackageService,
    private snipperOverlayService: SpinnerOverlayService,
    private sharedDataService: SharedDataService,
    private packageUtilService: PackageUtilService,
    private btlSharedLibraryService: BtlSharedLibraryService,
    private router: Router,
    private sharedService: SharedService,


  ) { }
  models: any[] = []
  displayedColumns: string[] = ['entity', 'distribution', 'area', 'model', 'lastupdated', 'actions'];
  taskGroups: any[] = [];
  currentTaskGroups: any = [];
  dataLoad = false;
  draftPackages: any[] = [];
  currentEditDraftPackage: any = null;
  isVisible: boolean = true;
  isCreateDisabled$ = this.sharedDataService.activateCreateButton$;
  page: number = 0;
  pageSizeOptions: number[] = [5];
  size: number = 10;
  rowdata: any
  dataSource: any;
  filterJson: any;
  current_user: any;
  totalmodels: number = 0;
  draftObj: any;
  draftpkgarr: any[] = [];

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  pageSize = 5;
  showPages = 2;
  pageLength = this.pageSize * (this.showPages - 1);


  pageNavigation(event: PageEvent) {

    this.page = event.pageIndex;
    this.size = event.pageSize;
    this.getAllDraftTrainingPackages();
    // this.searchFilters.next(filters);
  }

  ngOnInit(): void {
    this.sharedDataService.resetSearchFilters();
    this.sharedDataService.updateTaskGroups([]);
    this.sharedDataService.updateEditPackageHeaders(false);
    this.getCurrentUserRole();
    this.taskGroups = [];
    
  }



  getCurrentUserRole() {
    let current_user = this.sharedService.getUserInfo();
    this.isVisible = this.sharedService.isAdmin() || this.sharedService.isApprover();

    this.filterJson = { "createdBy": current_user.email };
    console.log("user", this.filterJson);
      this.getAllDraftTrainingPackages();
  }
  /**get all draft packages */
  getAllDraftTrainingPackages() {
    this.snipperOverlayService.show();
    console.log("filter", this.filterJson);
    this.trainingPackageService.getDraftTrainingPackages(this.filterJson, this.page, this.size).subscribe({
      next: (data) => {

        if (data.status === '200 OK') {
          this.draftPackages = data.data;
          this.draftPackages = this.draftPackages.sort((a, b) => a.airlineEntity.localeCompare(b.airlineEntity));
          this.sharedDataService.updateDraftPackages(data.data);
          console.log("data", this.draftPackages);
          this.draftPackages.filter((x) => {
            x.models.filter((rowmodels: any) => {
              this.draftObj = {
                "trainingPackageId": x.trainingPackageId,
                "airlineEntity": x.airlineEntity,
                "distribution": x.distribution,
                "area": x.area,
                "title": x.title,
                "createdBy": x.createdBy,
                "modifiedBy": x.modifiedBy,
                "modifiedAt": x.modifiedAt,
                "model": rowmodels.model,
                "taskgroup": rowmodels.taskGroups,
                "models": x.models

              }

              this.draftpkgarr.push(this.draftObj);

            })



          })

          console.log("model", this.currentTaskGroups);
          this.dataSource = new MatTableDataSource<any>(this.draftpkgarr);
          this.pageLength = this.draftpkgarr.length;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.snipperOverlayService.hide();

        }
      },
      error: (e) => {
        console.error(e);
        this.snipperOverlayService.hide();
      },
    });
    this.dataLoad = true;
  }


  showPreview(rowdata: any) {
    this.sharedDataService.updateRowData(rowdata);
    let taskGroup = rowdata.taskgroup[0];
    let task = taskGroup.tasks[0];
    let artifact = taskGroup.tasks[0].artifacts;
    console.log("artifact", artifact);
    this.sharedDataService.updateTask(task);
    this.sharedDataService.updateTaskGroup(taskGroup);
    this.sharedDataService.updateArtifacts(artifact);
    this.sharedDataService.updateTaskGroups(rowdata.taskgroup);
    console.log("rowdata-val",rowdata)
    this.sharedDataService.updatePage("editor");
    this.sharedDataService.updateCurrentEditDraftPackage(rowdata);
    this.sharedDataService.updateModelFilters(rowdata);
    this.router.navigate(['packages',rowdata.trainingPackageId, 'taskGroup', taskGroup.taskGroupName, 'task', task.taskName, 'preview']);
    this.sharedDataService.updateEditPackageHeaders(false);
    this.sharedDataService.updateshowPreviewPackageHeaders(true);
    this.sharedDataService.setSideBarType('preview');
  }

  editMode(rowdata:any) {
    this.sharedDataService.updateRowData(rowdata);
    let taskGroup = rowdata.taskgroup[0];
    this.sharedDataService.updateEditCount(1);
    if (taskGroup != null) {
      let task = taskGroup.tasks[0];
      if (task != null) {
        let artifact = taskGroup.tasks[0].artifacts;
        this.sharedDataService.setSideBarType('edit');
        this.sharedDataService.updateTask(task);
        this.sharedDataService.updateTaskGroup(taskGroup);
        this.sharedDataService.updateArtifacts(artifact);
        this.sharedDataService.updateModelFilters(rowdata);
        this.sharedDataService.updatePage("editor");
        this.sharedDataService.updateCurrentEditDraftPackage(rowdata);
        this.sharedDataService.updateTaskGroups(rowdata.taskgroup);
        this.router.navigate(['packages',rowdata.trainingPackageId, 'taskGroup', taskGroup.taskGroupName, 'task', task.taskName]); 
        this.sharedDataService.updateEditPackageHeaders(true);
        this.sharedDataService.updateshowPreviewPackageHeaders(false);
      }
      else {
        this.sharedDataService.setSideBarType('edit');
        this.sharedDataService.updateTaskGroup(taskGroup);
        this.sharedDataService.updateModelFilters(rowdata);
        this.sharedDataService.updateCurrentEditDraftPackage(rowdata);
        this.sharedDataService.updateTaskGroups(rowdata.taskgroup);
        this.router.navigate(['packages',rowdata.trainingPackageId]);
        this.sharedDataService.updateEditPackageHeaders(true);
        this.sharedDataService.updateshowPreviewPackageHeaders(false);
      }
    }
    else {
      
      this.sharedDataService.updateCurrentEditDraftPackage(rowdata);
      this.sharedDataService.updateTaskGroups(rowdata.taskgroup);
      this.sharedDataService.updateModelFilters(rowdata);
      this.router.navigate(['packages',rowdata.trainingPackageId]);
      this.sharedDataService.updateEditPackageHeaders(true);
      this.sharedDataService.updateshowPreviewPackageHeaders(false);
    }



  }

   
  navigateToApprovals(){
    
    this.router.navigate(["/approvals"]);
  }

  pageChangeEvent(event: PageEvent) {
    this.dataSource.paginator = this.paginator;
  }





  setTaskGroupAndTaskView(draftPackage: any) {
    // update the task view as per selected task-group .
    // set first task-group & task in view.
    
    if (this.taskGroups != null && this.taskGroups.length > 0 && this.taskGroups[0].tasks != null && this.taskGroups[0].tasks.length > 0) {
      let taskGroup = this.taskGroups[0];
      let task = taskGroup.tasks[0]
      this.sharedDataService.updateTask(task);
      this.router.navigate(['packages',draftPackage.trainingPackageId, 'taskGroup', taskGroup.taskGroupName, 'task', task.taskName]);
    }
  }

  /** update shared data to deactivate edit mode */
  updateEditHeadersData() {
    this.sharedDataService.updateEditPackageHeaders(false);
    this.btlSharedLibraryService.updateStatusMessage(false, "", false);
  }

  /** open edit mode for a new draft package creation */
  createNewDraftPackage() {

    this.packageUtilService.initializeDraftPackage();
  }


}

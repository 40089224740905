<h1 mat-dialog-title>Add Artifact</h1>
<div mat-dialog-content>
    <!-- <div class="d-flex justify-content-center section-container" > -->
        <form [formGroup]="reactiveForm">
            <div class="form-row">
                <mat-form-field class="form-full-width">
                    <mat-label class="form-header">Filename</mat-label>
                    <input matInput placeholder="Ex. Alaska-Airlines.ppt" formControlName="fileName">
                </mat-form-field>
                <mat-form-field class="form-full-width">
                    <mat-label class="form-header">Revision</mat-label>
                    <input matInput placeholder="Ex. 1.0" formControlName="revision">
                </mat-form-field>
            </div>
            <div class="form-row">
                <mat-form-field class="form-full-width">
                    <mat-label class="form-header">Artifact ID</mat-label>
                    <input matInput placeholder="Ex. AA_FTDOC_abcd:1.0" formControlName="artifactId">
                </mat-form-field>
                <mat-form-field class="form-full-width">
                    <mat-label class="form-header">Title</mat-label>
                    <input matInput placeholder="Ex. Flat Panel Trainer Introduction" formControlName="title">
                </mat-form-field>
            </div>

            <div class="form-row">
                <mat-form-field class="form-full-width">
                    <mat-label class="form-header">Update From</mat-label>
                    <input matInput [matDatepicker]="fromDatepicker" placeholder="" formControlName="update_from"
                        >
                    <mat-datepicker-toggle matSuffix [for]="fromDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromDatepicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="form-full-width">
                    <mat-label class="form-header">Update To</mat-label>
                    <input matInput [matDatepicker]="toDatepicker" placeholder="" formControlName="update_to"
                        >
                    <mat-datepicker-toggle matSuffix [for]="toDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #toDatepicker></mat-datepicker>
                </mat-form-field>
            </div>

        </form>
    <!-- </div> -->

    <div>
        <table mat-table [dataSource]="dataSource" class="commitment-table" matSort matSortActive="fileName"
        matSortDisableClear matSortDirection="desc" [class.loading]="artifactsLoading">

    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef style="width: 5%;"></th>
        <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleSelection(row)" [(ngModel)]="row.selected">
            </mat-checkbox>
        </td>
    </ng-container>
    <ng-container matColumnDef="fileName" sticky>
        <th mat-header-cell *matHeaderCellDef style="width: 25%;" mat-sort-header> Filename </th>
        <td mat-cell *matCellDef="let element"> {{element.fileName}} </td>
    </ng-container>
    <ng-container matColumnDef="revision" sticky>
        <th mat-header-cell *matHeaderCellDef style="width: 10%;" mat-sort-header> Revision </th>
        <td mat-cell *matCellDef="let element"> {{element.revision}} </td>
    </ng-container>
    <ng-container matColumnDef="artifactId">
        <th mat-header-cell *matHeaderCellDef style="width: 25%;" mat-sort-header > Artifact ID </th>
        <td mat-cell *matCellDef="let element"> {{element.artifactId}} </td>
    </ng-container>
    <ng-container matColumnDef="title" sticky>
        <th mat-header-cell *matHeaderCellDef style="width: 20%;" mat-sort-header> Title </th>
        <td mat-cell *matCellDef="let element"> {{element.title}} </td>
    </ng-container>
    <ng-container matColumnDef="updatedDate">
        <th mat-header-cell *matHeaderCellDef style="width: 15%;" mat-sort-header> Updated </th>
        <td mat-cell *matCellDef="let element"> {{element.updatedDate | date: 'yyyy-MM-dd h:mm a' }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
</table>

        <mat-paginator [pageSize]="3" style-paginator [pageSizeOptions]="[3, 5, 10, 20]"></mat-paginator>

    </div>


</div>

<div mat-dialog-actions class="d-flex justify-content-end">
    <button mat-button (click)="onNoClick()" class="btn-cancel mx-4">Cancel</button>
    <button mat-button [mat-dialog-close]="airlineControl.value" cdkFocusInitial class="btn-submit" (click)="onSubmit()"
        [disabled]="selectedArtifacts.length === 0">ADD SELECTED +</button>
</div>
import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private userInfoSubject = new Subject<User>();
  userInfo$ = this.userInfoSubject.asObservable();

  private userInfo!: User;
  private adGroups : any;

  constructor() { }

  setUserInfo(user: User) {
    this.userInfo = user;
    this.userInfoSubject.next(user);
  }

  getUserInfo(){
    return this.userInfo;
  }

  setAdGroups(adGroups : any){
    this.adGroups = adGroups;
  }

  getAdGroups() : any{
    return this.adGroups;
  }

  isApprover() : boolean{
    return this.getAdGroups().indexOf(environment.approver) > -1
  }

  isEditor() : boolean{
   return  this.getAdGroups().indexOf(environment.editor) > -1
  }

  isAdmin() : boolean{
    return this.getAdGroups().indexOf(environment.admin) > -1
  }

  isOnlyApprover(): boolean{
    return this.isApprover() && !this.isEditor() && !this.isAdmin()
  }

  isOnlyEditor(): boolean{
    return !this.isApprover() && this.isEditor() && !this.isAdmin()
  }

}

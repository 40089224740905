import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, map, startWith } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { ArtifactsService } from 'src/app/services/artifacts.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay-service';
import { MatSort } from '@angular/material/sort';
export interface TableElement {
  title: string;
  revision: number;
  fileName: string;
  artifactId: string;
  updatedDate: Date;
}

@Component({
  selector: 'app-add-artifact-dialog',
  templateUrl: './add-artifact-dialog.component.html',
  styleUrls: ['./add-artifact-dialog.component.scss']
})
export class AddArtifactDialogComponent implements OnInit, OnDestroy {
  
  formSubscription: Subscription | undefined;
  reactiveForm: FormGroup;
  airlineControl = new FormControl('');
  group1Control = new FormControl('');
  options: string[] = [];
  airlineOptions: Observable<string[]> | undefined;
  showErrorMessage: boolean = false;
  showSubmitButton: boolean = false;
  fileName: any;
  airlineCode: any;
  inputValid: boolean = false;
  columns: string[] = ['select', 'fileName', 'revision', 'artifactId', 'title', 'updatedDate'];
  // dataSource: any;
  dataSource = new MatTableDataSource<TableElement>();
  selectedArtifacts: any[] = [];
  artifactsLoading: boolean = false;
  searchParam: any = {};
  previousSearchParam: any = {};
  constructor(
    public dialogRef: MatDialogRef<AddArtifactDialogComponent>,
    private _fb: FormBuilder,
    private artifactsService: ArtifactsService,
    private sharedDataService: SharedDataService,
    private spinnerOverlayService: SpinnerOverlayService,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {
    this.reactiveForm = this._fb.group({
      fileName: [''],
      revision: [''],
      artifactId: [''],
      title: [''],
      update_from: [''],
      update_to: [''],
    });
  }
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  ngOnInit(): void {
    this.sharedDataService.selectedValues$.subscribe(selectedvalue => {
      this.airlineCode = selectedvalue['airlineEntity'];
    })
    this.subscribeToFormChanges();

  }

  subscribeToFormChanges() {
    Object.keys(this.reactiveForm.controls).forEach(controlName => {
      this.reactiveForm.get(controlName)!.valueChanges.subscribe(value => {
        this.onFormControlChange(controlName, value);
      });
    });
  }

  onFormControlChange(controlName: string, value: any) {
    if (controlName === 'update_from' || controlName === 'update_to') {
      this.onDateChange(controlName, value);
    } else {
      this.onTextChange(controlName, value);
    }
  }

  onTextChange(controlName: string, value: string) {
    value = value.trim();
    if (value.length >= 3 || (controlName === 'revision' && value.length >= 1)) {
      this.searchParam[controlName] = value;
    } else if (value.length === 0) {
      delete this.searchParam[controlName];
    } else {
      delete this.searchParam[controlName];
    }
    this.checkAndLoadArtifacts();
  }

  onDateChange(controlName: string, value: Date) {
    if (this.isValidDate(value)) {
      this.searchParam[controlName === 'update_from' ? 'fromDate' : 'toDate'] = `${value.getDate()}-${value.getMonth() + 1}-${value.getFullYear()}`;
    } else {
      delete this.searchParam[controlName === 'update_from' ? 'fromDate' : 'toDate'];
    }
    this.checkAndLoadArtifacts();
  }
  isValidDate(date: any): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }


  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.selectedArtifacts)
    console.log("Submit")
  }

  checkboxSelected(row: any) {
    console.log(row);
  }

  ngAfterViewInit(): void {
    if (this.sort && this.paginator) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }
  checkAndLoadArtifacts() {
    // Check if searchParam is empty
    if (!this.searchParam || Object.keys(this.searchParam).length === 0) {
      this.dataSource = new MatTableDataSource<TableElement>([]);
      if (this.paginator){
        this.dataSource.paginator = this.paginator;
      }
      this.previousSearchParam = { ...this.searchParam };
      setTimeout(() => {
        this.triggerPaginatorFalseClick();
      }, 50);
      return;
    }
  
    // Check if searchParam has changed
    if (this.hasSearchParamChanged()) {
      this.previousSearchParam = { ...this.searchParam };
      this.loadArtifacts(this.searchParam);
    }
  }

  hasSearchParamChanged(): boolean {
    return JSON.stringify(this.searchParam) !== JSON.stringify(this.previousSearchParam);
  }


  loadArtifacts(searchParams: any) {
    this.spinnerOverlayService.show();
    this.artifactsService.searchArtifacts(this.airlineCode, searchParams.fileName, searchParams.revision, searchParams.artifactId, searchParams.title, searchParams.fromDate, searchParams.toDate).subscribe(
      (data) => {
        console.log(data);
        this.dataSource = new MatTableDataSource<TableElement>(data.data);
        if (this.paginator && this.sort) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.paginator.length = data.data.length;
        }
        this.artifactsLoading = false;
        this.spinnerOverlayService.hide();
        setTimeout(() => {
          this.triggerPaginatorFalseClick();
        }, 50);

      },
      (error) => {
        this.artifactsLoading = false;
        console.error('Error fetching artifacts:', error);
        this.spinnerOverlayService.hide();
      }
    );
  }



  toggleSelection(row: any) {
    if (row.selected) {
      this.selectedArtifacts.push(row);
    } else {
      const index = this.selectedArtifacts.indexOf(row);
      if (index > -1) {
        this.selectedArtifacts.splice(index, 1);
      }
    }
    console.log("selectedArtifacts", this.selectedArtifacts);
  }

  triggerPaginatorFalseClick() {
    this.paginator?.nextPage();
    this.paginator?.previousPage();
    this.paginator?.page.next({
      pageIndex: 1,
      pageSize: this.paginator.pageSize,
      length: this.paginator.length
    });
  }


}

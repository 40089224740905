import {Component} from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { DatePipe } from '@angular/common';
import { saveAs as importedSaveAs } from "file-saver";
import { Inject, ViewChild, Input, Output, EventEmitter,OnInit, ViewChildren,QueryList, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {MatDividerModule} from '@angular/material/divider';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay-service';
import { SharedService } from 'src/app/services/shared.service';
import { BtlSharedLibraryService, DialogData } from 'btl-shared-library';
import { ArtifactsService } from 'src/app/services/artifacts.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { PackageUtilService } from 'src/app/services/package-util.service';
// import { HistoryElement, HistoryResponse } from 'src/app/models/history-element.model';
// import { HistoryMapping } from 'src/app/models/history.model';
// import { ExportService } from 'src/app/services/export.service';
// import { IntegrationService } from 'src/app/services/integration.service';
// import { SharedService } from 'src/app/services/shared.service';
// import { SpinnerOverlayService } from 'src/app/services/spinner-overlay.service';
/**
 * @title Dialog with header, scrollable content and actions
 */
@Component({
  selector: 'dialog-content-example',
  templateUrl: './artifacts-approval-popup.component.html',
  styleUrls: ['./artifacts-approval-popup.component.scss'],
 
})
export class ArtifactsApprovalViewPopupComponent {
  columns: any[] = [
    // 'artifactid',
    // 'title',
    // 'submitted',
    // 'accept'
  ];
  displayedColumns: string[] = ['artifactId','view', 'field', 'title', 'update','submitted', 'accept'];
  // dataSource = new MatTableDataSource<Artifact>;
  dataLoad = false;
  
  popupData:any;
  user: string = "admin";
  updatesvalue : string = "Update";
  page: number = 0;
  pageSizeOptions: number[] = [10];
  size: number = 7;
  pageLength = 50;
  revision:number = 1;
  artifactIdHeader : string = ""
  currentHeaderTitle:string = "";
  currentDocFormat:string = "";
  currentDesc :string = "";
  currrentartifactID :any;
  currentArtifactMetaData:any;
  allUpdateArtifacts:any[]=[];
  allCurrentArtifacts:any[]=[];
  status:string = "";
  tableData:any[]=[];
  sample:any[]=[];
  currentObj:any = {};
  
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  fieldType: string ='';


  constructor(private artifactsService: ArtifactsService,
    private sharedDataService: SharedDataService,
    private spinnerOverlayService: SpinnerOverlayService,
    private btlSharedLibraryService: BtlSharedLibraryService,
    private packageUtilService:PackageUtilService,
    @Inject (MAT_DIALOG_DATA) public data: ArtifactsApprovalViewPopupComponent,
    public dialog: MatDialog,) { }
    artifactPopupCurrent: any=[
      {status:"CURRENT"},
    {header:"737 NPDNPD Flight Crew Operations Manual FCOM - Volume 1A"},
    {file:"737NG EASA MCC Type Rating FFS11 Briefing.pptx"},
    {desc:"When you already have your Boeing 737 NG Type Rating, you can complete a Difference course to get authorized to operate the fourth generation Boeing 737 Max"}
    ]
    
    artifactPopupUpdates: any=[]
    //   {status:"UPDATE"},
    // {header:"737 NPDNPD Flight Crew Operations Manual FCOM - Volume 1A"},
    // {file:"737NG EASA MCC Type Rating FFS11 Briefing.pptx"},
    // {desc:"When you already have your Boeing 737 NG Type Rating, you can complete a Difference course to get authorized to operate the fourth generation Boeing 737 Max"}
    // ]
    artifactMetadata: any = [
      { name: "Revision", value: "D" },
      { name: "Updated", value: "June 7 2024" },
      { name: "Regulator", value: "India DGCA" },
      { name: "Course Type", value: "Speciality" },
    ]
  ngOnInit(): void {
    this.loadData();
    // console.log(JSON.stringify(this.data));
  }

  // pageNavigation(event: PageEvent) {
  //   this.page = event.pageIndex;
  //   this.size = event.pageSize;
  //   console.log(event);
  //   this.loadData();
  // }
  onAcceptAction(row: any, id:any,status:string) {
    this.spinnerOverlayService.show();
    console.log("into accept", row)
    const requestBody = {
      artifactIds: [id],
    };
   
this.fieldType = row.updatedField;
    this.artifactsService.approveArtifacts(requestBody, this.user, this.fieldType, )
      .subscribe(response => {
        
        
        this.btlSharedLibraryService.updateStatusMessage(true, `${response.message}`, true);
        this.resetData(id,status)

      }, error => {
        console.error('Error approving artifacts:', error);
        this.spinnerOverlayService.hide();
        const errorMessage = error.error && error.error.message ? error.error.message : "An error occurred during the request.";
        this.btlSharedLibraryService.updateStatusMessage(true, errorMessage, false);
      },
        () => {
          this.spinnerOverlayService.hide();
        }
      );
    window.scroll(0, 0)
  }
  onRejectAction(row: any, id:any, status:string) {
    this.spinnerOverlayService.show();
    const requestBody = {
      artifactIds: [id],
    };
    this.fieldType = row.updatedField;
    // console.log("into reject",row.artifactId)
    this.artifactsService.rejectArtifact(requestBody, this.user,this.fieldType)
      .subscribe(response => {
        console.log(' response:',response);
        this.spinnerOverlayService.hide();
        this.btlSharedLibraryService.updateStatusMessage(true, `${response.message}`, true)
        this.resetData(id, status)
      }, error => {
        console.error('Error rejectung artifacts:', error);
        this.spinnerOverlayService.hide();
        const errorMessage = error.error && error.error.message ? error.error.message : "An error occurred during the request.";
        this.btlSharedLibraryService.updateStatusMessage(true, errorMessage, false);
      },
        () => {
          this.spinnerOverlayService.hide();
        }
      );
    window.scroll(0, 0)
  }

  updateMetadata(artifacts:any,type:string){
    // Fetch metadata
    this.spinnerOverlayService.show();
    this.packageUtilService.appendMetadataToArtifact(artifacts,'approval').then(updatedArtifacts => {
      // Update artifacts with metadata
      if(type === 'current'){
        this.currentArtifactMetaData = updatedArtifacts[0].metadata;
        this.revision = this.currentArtifactMetaData.artifactMetaData.Revision;
        // console.log('metadata',this.revision)
      }else{
        this.allUpdateArtifacts=updatedArtifacts;
      }
    });
    this.spinnerOverlayService.hide();
  }

  loadData(): void {
    // this.spinnerOverlayService.show();
    this.popupData = this.data;
    this.currentObj = this.popupData.rowdata.current;
    
    console.log("popupData"+JSON.stringify(this.popupData.rowdata.artifactId));
    this.artifactIdHeader = this.popupData.rowdata.artifactId;

    // fetch metadata for current artifact
    if(this.popupData.rowdata.current!==null){
      let artifacts=[];
      artifacts.push(this.popupData.rowdata.current);
      this.updateMetadata(artifacts,'current');
    }
    
    if(this.popupData.rowdata.updates.length>1){
       this.updatesvalue = "  Updates"
    }
    if(this.popupData.rowdata.current !== null){
      this.status = "CURRENT";
    }
    this.tableData.push(this.popupData.rowdata);
    this.allUpdateArtifacts = (this.popupData.rowdata.updates);

    // this.allCurrentArtifacts.push(this.popupData.rowdata.current) ;

    // fetch metadata for udpated artifacts
    this.updateMetadata(this.allUpdateArtifacts,'updated');
    // this.revision = this.currentArtifactMetaData;
    // console.log("updates", this.revision);
    // this.artifactsService.getApprovalArtifacts(this.page, this.size).subscribe({
    //   next: (data) => {
    //     if (data.status === '200 OK') {
    //       this.TableData = data.data;
    //       // this.TableData.forEach(item => {
    //       //   Object.defineProperty(item, 'displayArtifactId', { value: item.artifactId.split('_update_')[0], writable: true });
    //       // });
    //       // this.dataSource = new MatTableDataSource<Artifact>(this.TableData);
         
        
    //     }
    //     this.spinnerOverlayService.hide();
    //   },
    //   error: (e) => {
    //     console.error(e);
    //     const errorMessage = e.error && e.error.message ? e.error.message : "An error occurred during the request.";
    //     console.log("Error ::",errorMessage);
    //   }
    // })
    this.dataLoad = true;
    
  }

  triggerPaginatorFalseClick() {
    this.paginator?.nextPage();
    this.paginator?.previousPage();
  }

  openArtifactsApprovalDialog(): void {
    const dialogRef = this.dialog.open(ArtifactsApprovalViewPopupComponent,);
    dialogRef.afterClosed().subscribe((result: any) => {
      
    });
  }
  
  removeAfterLastPattern(str:string, substring:string) {
    let index = str.lastIndexOf(substring);
    if (index !== -1) {
        return str.substring(0, index);
    }
    return str;
  }

  onClickDownloadArtifact(artifact: any) {
    console.log("Download:artifact: in popup screen",artifact);
    this.spinnerOverlayService.show();

    let modifiedArtifactId = artifact.artifactId;
    if(artifact.artifactId.includes('_APPROVAL_')){
      modifiedArtifactId= this.removeAfterLastPattern(artifact.artifactId, '_APPROVAL_');
    }else if(artifact.artifactId.includes('_IN_WORK_')){
      modifiedArtifactId= this.removeAfterLastPattern(artifact.artifactId, '_IN_WORK_');
    }
    console.log("Downloading artifact :=>",modifiedArtifactId);
    
    this.artifactsService.downloadArtifact(modifiedArtifactId, 'FTDOC')
      .subscribe({
        next: (data) => {
          if (data.type === HttpEventType.Response) {
            importedSaveAs(data.body, artifact.fileName);
            this.spinnerOverlayService.hide();
          }
        },
        error: (e) => {
          let errorMessage = "";
          
          e.error.text().then((text: any) => {
            try {
              errorMessage = text;
              console.log("TExt error::",text)

            } catch (jsonError) {
            } finally {
              this.btlSharedLibraryService.updateStatusMessage(true, errorMessage, false);
              window.scroll(0, 0);
              this.spinnerOverlayService.hide();
            }
          })
        }
      })
  }

  resetData(id:any, status:string) {

    if(status === 'accept'){
      console.log("accepted");
         let current_data = this.allUpdateArtifacts.filter(x=>x.artifactId === id);
         console.log(current_data);
         this.currentObj = current_data[0];
         this.allUpdateArtifacts = this.allUpdateArtifacts.filter((x) => {return x.artifactId !== id});   
         //  console.log(updated);
          // this.allUpdateArtifacts = updated;
       console.log("refreshed",this.allUpdateArtifacts,this.currentObj)
    }

    if(status === 'reject'){

      this.allUpdateArtifacts = this.allUpdateArtifacts.filter((x) => {
        return x.artifactId !== id}); 
      console.log("reject",this.allUpdateArtifacts);
    }

  }
  
}

import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { TrainingPackageService } from 'src/app/services/training-package-service';
import { ArtifactsService } from 'src/app/services/artifacts.service';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay-service';
import { SharedService } from 'src/app/services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-artifact-approval-parent',
  templateUrl: './artifact-approval-parent.component.html',
  styleUrls: ['./artifact-approval-parent.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ArtifactApprovalParentComponent implements OnInit {

  selectAllCheckboxValue: boolean = false;
  activeTabIndex = 0;
  selectedTrainigPackageIds: string[] = [];
  selectedArtifactIds: string[] = [];
  user: string = "admin";
  headerText: string = "Artifact"
  isVisible: boolean = false;


  constructor(private sharedDataService: SharedDataService,
    private artifactService: ArtifactsService,
    private trainingPackageService: TrainingPackageService,
    private spinnerOverlayService: SpinnerOverlayService,
    private sharedService: SharedService,
    private router: Router) { }

  ngOnInit(): void {

    this.getCurrentUserRole();
  }

  getCurrentUserRole() {
    if (this.sharedService.isApprover() && !this.sharedService.isEditor()) {
      if (this.router.url != "/approvals" && this.router.url != "/") {
        this.router.navigate(["unauthorized"]);
      }
      else {
        this.router.navigate(["/approvals"])
      }
    }

    this.isVisible = this.sharedService.isAdmin() || this.sharedService.isEditor();
  }

  tabChanged(index: any) {
    if (index === 0) {
      this.headerText = "Artifact";
    }
    else if (index === 1) {
      this.headerText = "Package";
    }
  }

  navigateToEditor() {
    this.sharedDataService.setSideBarType('edit');
    this.sharedDataService.resetSearchFilters();
    this.sharedDataService.updateTaskGroups([]);
    this.router.navigate(["packages"]);
  }


}



import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArtifactsService {


  constructor(
    private http: HttpClient) { }

  getApprovedArtifactsFromCommitmentService(page: number, size: number): Observable<any> {
    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Ocp-Apim-Subscription-Key', environment.btlTpSubscriptionKey);
    let params: HttpParams = new HttpParams();
    params = params.set('size', size);
    params = params.set('page', page);
    return this.http.get<any>(`${environment.backendBaseUrl}/artifacts?artifactType=all`, {
      headers: httpHeaders,
      params
    });
  }

  downloadArtifact(
    artifactVersion: any,
    docType: any
  ): Observable<HttpEvent<any>> {
    let url =
      environment.btlCommitmentBaseUrl +
      `/artifact-approval/${artifactVersion}/doctype/${docType}/download`;

    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Ocp-Apim-Subscription-Key', environment.btlCommitmentSubscriptionKey);

    return this.http.get(url, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob',
      headers: httpHeaders.delete('Content-Type'),
    });
  }

  getApprovalArtifacts(): Observable<any> {
    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Ocp-Apim-Subscription-Key', environment.btlTpSubscriptionKey);

    // this.http.get<any>(`${environment.backendBaseUrl}/artifacts?artifactType=approval`
    return this.http.get<any>(`${environment.backendBaseUrl}/artifacts/approvals-list`, {
      headers: httpHeaders,
    });
  }

  approveArtifacts(requestBody: any, userId: string, fieldType: string): Observable<any> {
    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Ocp-Apim-Subscription-Key', environment.btlTpSubscriptionKey);
    // /artifacts/process-approval-fieldtype?action={action}&approver={approver}&fieldType={fieldType}
    return this.http.post<any>(`${environment.backendBaseUrl}/artifacts/process-approval-fieldtype?action=approve&approver=${userId}&fieldType=${fieldType}`,
      requestBody, {
      headers: httpHeaders
    });
  }

  rejectArtifact(requestBody: any, userId: string, fieldType: string): Observable<any> {
    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Ocp-Apim-Subscription-Key', environment.btlTpSubscriptionKey);
    return this.http.post<any>(`${environment.backendBaseUrl}/artifacts/process-approval-fieldtype?action=reject&approver=${userId}&fieldType=${fieldType}`,
      requestBody, {
      headers: httpHeaders
    });
  }



  getArtifactMetadata(artifactIds: string[]): Observable<any> {
    const docType = 'FTDOC';
    const searchCriteria = { "contentKeyIds": artifactIds }
    const metadataParam = encodeURIComponent(JSON.stringify(searchCriteria));
    const url = `${environment.cmsBaseUrl}/api/documents/doctype/${docType}/metadata/${metadataParam}?order=ASCENDING&page=1&perPage=1000`;
    console.log("Metadata CMS URL : ", url);
    const headers = new HttpHeaders({
      'cms-app-id': 'CMS_INTERNAL',
      'Cache-Control': 'no-cache',
      'Ocp-Apim-Subscription-Key': environment.cmsSubscriptionKey
    });
    const options = { headers: headers };
    return this.http.get<any>(url, options);
  }
  
  searchArtifacts(airlineCode: string, filename?: string, revision?: string, artifactId?: string, title?: string, fromDate?: string, toDate?: string): Observable<any> {
    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Ocp-Apim-Subscription-Key', environment.btlTpSubscriptionKey);

    let params: HttpParams = new HttpParams()
      .set('airlineCode', airlineCode);

    // Adding optional parameters if provided
    if (filename) params = params.set('filename', filename);
    if (revision) params = params.set('revision', revision);
    if (artifactId) params = params.set('artifactId', artifactId);
    if (title) params = params.set('title', title);
    if (fromDate) params = params.set('fromDate', fromDate);
    if (toDate) params = params.set('toDate', toDate);

    return this.http.get<any>(`${environment.backendBaseUrl}/artifacts/search-artifacts`,{ headers: httpHeaders, params: params });
  }
}

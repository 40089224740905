import { SelectionModel } from '@angular/cdk/collections';
import { Component, HostListener, OnInit, Input, ElementRef, Renderer2, AfterViewInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { SpinnerOverlayService } from '../../../services/spinner-overlay-service';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { TrainingPackageService } from 'src/app/services/training-package-service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PackageUtilService } from 'src/app/services/package-util.service';
import { TaskGroup, Model, DraftTrainingPackage, Task } from 'src/app/models/training-package.model';
import { take, switchMap } from 'rxjs/operators';
import { EMPTY, of, Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ThisReceiver } from '@angular/compiler';
import { StylePaginatorDirective } from 'src/app/directives/style-paginator.directive';

@Component({
  selector: 'app-task-groups',
  templateUrl: './task.groups.component.html',
  styleUrls: ['./task.groups.component.scss']
})
export class TaskGroupsComponent implements OnInit {
  @Input() taskGroups: any[] = [];
  @Input() model: any;
  @Input() currentEditDraftPackage: any[] = [];
  isCreateDisabled$ = this.sharedDataService.activateCreateButton$;
  isEditModeActive$ = this.sharedDataService.showEditPackageHeaders$;
  isTaskGroupInputVisible = false;
  taskGroupName = '';
  isTaskInputVisible: boolean[] = [];
  taskName = '';
  currentDraftPackage: DraftTrainingPackage | null;
  currentModel: any= [];
  currentTaskGroups: TaskGroup[] = [];
  currentTaskGroup: TaskGroup | null | undefined;
  currentTask: Task | null | undefined;
  

  trainingPackageId: any;
  
  editCount: number = 0;
  @Input() tasks: any[] = [];
  taskGroupOrder: number = 0;
  taskGroupId: string = "";
  taskComponentsVisibility: any | null;
  groupComponentVisibility: boolean[];
  showEditPackageHeaders: boolean = false;
  showPreviewPackageHeaders: boolean = false;
  selectedIndex: any;
  selectedTaskId: string = "";
  selectedTaskGroupId: string = "";
  
  @ViewChild('taskGroupInput') taskGroupInput!: ElementRef;

  constructor(
    private snipperOverlayService: SpinnerOverlayService,
    private sharedDataService: SharedDataService,
    private packageUtilService: PackageUtilService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.currentDraftPackage = null;
    this.taskComponentsVisibility = new Map();
    this.groupComponentVisibility = [];
  }

  ngOnInit(): void {
    // subscribe to edit count
    this.sharedDataService.editCount$.subscribe(count => {
      this.editCount = count;
    })

    // subscribe to current draft package
    this.sharedDataService.currentEditDraftPackage$.subscribe(draft => {
      if (draft != null) {
        this.currentDraftPackage = draft;
        this.trainingPackageId = draft.trainingPackageId;
      } else {
        this.router.navigateByUrl("");
      }
    })

    // subscribe to current model
    this.sharedDataService.currentModel$.subscribe((model) => {
      this.currentModel = model;
    })

    //subscribe to current task-group list
    this.sharedDataService.taskGroups$.subscribe(taskGroups => {
      console.log("taskGroups",taskGroups);
      this.currentTaskGroups = taskGroups;
    })

    this.sharedDataService.currentTask$.subscribe(currentTask => {
      this.currentTask = currentTask;
    })

    this.sharedDataService.currentTaskGroup$.subscribe(taskGroup => {
      this.currentTaskGroup = taskGroup;
    })



    // get show headers condition
    this.sharedDataService.showEditPackageHeaders$.subscribe((showEditPackageHeaders) => {
      this.showEditPackageHeaders = showEditPackageHeaders;
    });

    this.sharedDataService.showPreviewPackageHeaders$.subscribe((showPreviewPackageHeaders) => {
      this.showPreviewPackageHeaders = showPreviewPackageHeaders;
    });

  }

  drop(event: CdkDragDrop<string[]>, taskGroup: TaskGroup) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      let [...task] = event.container.data;
      this.updateTaskOrder(task, taskGroup);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }


  dropGroup(event: CdkDragDrop<{ 'title': string, 'tasks': string[] }[]>) {
    console.log("Drop group event")
    if (event.previousContainer === event.container) {
      console.log("Move item in array:", event.container.data, event.previousIndex, event.currentIndex)
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      let [...taskGroups] = event.container.data;
      this.updateTaskGroupOrder(taskGroups);
    } else {
      console.log("Transfer array Item:", event.container.data, event.previousIndex, event.currentIndex)
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  updateTaskGroupOrder(taskGroups: any) {
    let reOrderedTaskGroups: TaskGroup[] = taskGroups;
    let updatedTaskGroups: TaskGroup[] = [];
    // assign current index as new taskGroup Order.
    reOrderedTaskGroups.map((taskGroup, index) => {
      updatedTaskGroups.push({ ...taskGroup, taskGroupOrder: index + 1 })
    })
    console.log("Updated TaskGroups with new orders::", updatedTaskGroups);
    // update taskgroups with updated order.
    this.saveTaskGroup('udpate', updatedTaskGroups)
  }


  updateTaskOrder(task: any, taskGroup: any) {
    let reOrderedTasks: Task[] = task;
    let updatedTasks: Task[] = [];
    // assign current index as new taskGroup Order.
    reOrderedTasks.map((task, index) => {
      updatedTasks.push({ ...task, taskOrder: index + 1 })
    })
    console.log("Updated task with new orders::", updatedTasks);
    // update taskgroups with updated order.
    this.saveTaskOrder('update', updatedTasks, taskGroup)
  }

  addTaskGroup() {

    
    if (this.showEditPackageHeaders) {
      
      // edit mode active
      this.toggleTgInputVisibility();
      if (!this.isTaskGroupInputVisible) {
        this.taskGroupName = '';
        this.toggleTgInputVisibility();
      }
    }
    if (!this.showEditPackageHeaders && !this.showPreviewPackageHeaders) {
      // navigate to edit
      console.log("EDIT MODE NOT  ACTIVE :CREATE NEW TP");
      this.packageUtilService.initializeDraftPackage();
    }
  }

  toggleTgInputVisibility() {
    
    this.isTaskGroupInputVisible = !this.isTaskGroupInputVisible;
  }

  openTaskView(taskGroup: TaskGroup, task: Task): void {
    console.log("Open task:", task, taskGroup);
    // update shared data for <app-draft-artifact-section> component
    this.sharedDataService.updateTask(task);
    this.sharedDataService.updateTaskGroup(taskGroup);

    // navigate to show task-details & artifacts.
    this.router.navigate(['packages',this.trainingPackageId, 'taskGroup', taskGroup.taskGroupName, 'task', task.taskName]);
  }

  onInputEnter(inputType: string, currentTaskGroup: any, index: any) {
    if (inputType === 'taskGroup') {
      if (this.taskGroupName.trim() !== '') {
        this.saveTaskGroup('add', []);
      } else {
        this.selectedTaskGroupId = ""
        this.saveTaskGroup('edit', this.currentTaskGroups);
      }
    } else if (inputType === 'task') {
      if (this.taskName.trim() !== '') {
        this.saveTaskName(currentTaskGroup, index);
      } else {
        this.toggleTaskInputVisibility(index);
      }
    }
  }

  onInputBlur(inputType: string, currentTaskGroup: any, index: any) {
    if (currentTaskGroup?.taskGroupId == this.selectedTaskGroupId){
    if (inputType == 'taskGroup') {
      if (this.taskGroupName.trim() !== '') {
        this.saveTaskGroup('add', []);
      } else {
        this.selectedTaskGroupId = ""
        this.saveTaskGroup('edit', this.currentTaskGroups);
      }
    } else if (inputType == 'task') {
      if (this.taskName.trim() != '') {
        this.saveTaskName(currentTaskGroup, index);
      } else {
        this.toggleTaskInputVisibility(index);
      }
    }
  }
  }

  saveTaskGroup(action: string, updatedTaskGroups: TaskGroup[]) {
    this.sharedDataService.updateEditCount(this.editCount);
    
    this.sharedDataService.currentEditDraftPackage$
      .pipe(
        take(1),
        switchMap((draft) => {
          if (action === 'add') {

            // if this is the first task-group
            if (this.taskGroups == null || undefined) {
              this.taskGroups = [];
              
            }
            // create new task-group object
            let newTaskGroup: TaskGroup = {
              
              taskGroupName: this.taskGroupName,
              status:"new",
              taskGroupOrder: this.taskGroups != null ? this.taskGroups.length + 1 : 1
            };



            // update the current task group list
            if (newTaskGroup.taskGroupOrder) {
              this.groupComponentVisibility[newTaskGroup.taskGroupOrder] = false;
            }

            console.log("task-grps for add group",this.taskGroups);
            this.taskGroups.push(newTaskGroup);
          } else {

            // update a task-group [taskGroupOrder]
            this.taskGroups = updatedTaskGroups;
          }

          // update model with updated task-group list
          let updatedModel: Model = {
            model: this.model,
            taskGroups: this.taskGroups,
            updated : false
          };



          // update model of package

          let updatedDraft: DraftTrainingPackage = this.packageUtilService.convertToDraftTrainingPackage(draft);


          if (updatedDraft.models != null) {
            const modelIndex = updatedDraft.models.findIndex(
              (existingModel) => existingModel.model === updatedModel.model
            );
            updatedDraft.models[modelIndex].taskGroups = this.taskGroups;

            // Return the updated draft for further processing
            return of(updatedDraft);
          }
          // If models is null or no matching model is found, return an empty observable
          return EMPTY;
        })
      )
      .subscribe((updatedDraft) => {
        // Notify the subscribers about the updated draft-training-package
        this.sharedDataService.updateCurrentEditDraftPackage(updatedDraft);
        
        this.sharedDataService.updateCurrentDraftData(updatedDraft, this.model)

      });

    this.taskGroupName = '';
    action === 'add' ? this.toggleTgInputVisibility() : null;
  }


  toggleTaskInputVisibility(index: any): void {
    this.isTaskInputVisible[index] = !this.isTaskInputVisible[index];
  }

  addNewTask(index: any) {
    this.toggleTaskInputVisibility(index);
  }

  saveTaskName(currentTaskgroup: any, index: any) {
    this.toggleTaskInputVisibility(index);


    this.sharedDataService.updateEditCount(this.editCount);
    // init new task object
    let newTask: Task = {
      taskId: uuidv4(),
      status: "new",
      taskOrder: currentTaskgroup.tasks != null ? currentTaskgroup.tasks.length + 1 : 1,
      taskName: this.taskName,
      taskDescription: '',
      artifacts: [],
      artifactOrder: []
    }
    // initialize task list if null
    if (currentTaskgroup && currentTaskgroup.tasks == null) {
      currentTaskgroup.tasks = [];
    }
    // update the existing task-group with the new taskName
    if (currentTaskgroup) {
      newTask.taskOrder = Number(String(currentTaskgroup.taskGroupOrder) + String(currentTaskgroup.tasks.length));
      this.taskComponentsVisibility.set(newTask.taskOrder, false);
      currentTaskgroup.tasks.push(newTask);
    }
    // update shared data for <app-draft-artifact-section> component
    this.sharedDataService.updateTask(newTask);
    this.sharedDataService.updateTaskGroup(currentTaskgroup);



    this.sharedDataService.currentTaskGroup$.subscribe(tg => {

    })

    // reset input field
    this.taskName = '';

    // navigate to show task-details & artifacts.
    this.router.navigate(['packages',this.trainingPackageId, 'taskGroup', currentTaskgroup.taskGroupName, 'task', newTask.taskName]);

  }
  updateDeletedTaskGroup(event: Event, index: number, currentTaskGroup: any) {

    event.stopImmediatePropagation();
   
    let existingModel: Model | undefined;
    let existingTaskGroups: TaskGroup[] | undefined;
    let tasksInTaskGroup: Task[] | undefined;
    this.selectedTaskGroupId = ''
    tasksInTaskGroup = currentTaskGroup.tasks;
    // get existing model
    
    existingModel = this.currentDraftPackage?.models?.find(m => m.model == this.currentModel[0]?.model);
   
    if(existingModel != undefined) {
      
      existingTaskGroups = existingModel.taskGroups;
     
    }

    if (existingTaskGroups != undefined) {
      // existingTaskGroups = existingTaskGroups.filter(taskGroup => taskGroup !== currentTaskGroup);
      this.taskGroups = currentTaskGroup;
    }

    // replace the existing task-groups in the existing model
    if (existingModel !== undefined) {
      existingModel.taskGroups = currentTaskGroup;
    }

    // replace the existing model in the draft
    if (existingModel !== undefined && this.currentDraftPackage != null && this.currentDraftPackage.models != null) {
      const index = this.currentDraftPackage?.models?.findIndex(m => m === existingModel);
      if (index !== undefined && index !== -1) {
        this.currentDraftPackage.models[index] = existingModel;
      }
    }

    

    this.sharedDataService.updateEditCount(this.editCount);
  }

  updateDeletedTask(event: Event, currentTaskGroup: any, updatedTasks: any) {
    event.stopImmediatePropagation();
    let existingModel: Model | undefined;
    let existingTaskGroups: TaskGroup[] | undefined;

    this.selectedTaskGroupId = '';

    // Get existing model
    existingModel = this.currentDraftPackage?.models?.find(m => m.model === this.currentModel);

    if (existingModel !== undefined) {
        existingTaskGroups = existingModel.taskGroups;
    }

    if (existingTaskGroups !== undefined) {
        // Find the task group with the same taskGroupId as currentTaskGroup
        const taskGroupIndex = existingTaskGroups.findIndex(group => group.taskGroupId === currentTaskGroup.taskGroupId);

        if (taskGroupIndex !== -1) {
            // Create a copy of the current task group and update its tasks
            const updatedTaskGroup = { ...existingTaskGroups[taskGroupIndex], tasks: updatedTasks };

            // Replace the old task group with the updated one
            existingTaskGroups[taskGroupIndex] = updatedTaskGroup;

            // Replace the existing task groups in the existing model
            if (existingModel !== undefined) {
            existingModel.taskGroups = existingTaskGroups;
            }
            // Replace the existing model in the draft
            if (existingModel !== undefined && this.currentDraftPackage != null && this.currentDraftPackage.models != null) {
                const modelIndex = this.currentDraftPackage.models.findIndex(m => m.model === this.currentModel);
                if (modelIndex !== -1) {
                    this.currentDraftPackage.models[modelIndex] = existingModel;
                }
            }

            // Update the task group in the shared data service
            // this.sharedDataService.updateTaskGroup(updatedTaskGroup);
        } else {
            console.error('Task group not found:', currentTaskGroup.taskGroupId);
        }
    } else {
        console.error('No existing task groups found in the model.');
    }

    // Update the edit count
    this.sharedDataService.updateEditCount(this.editCount);
}



  saveTaskOrder(action: string, updatedTasks: Task[], currentTaskGroup: TaskGroup) {
    this.sharedDataService.updateEditCount(this.editCount);
    this.sharedDataService.currentEditDraftPackage$
      .pipe(
        take(1),
        switchMap((draft) => {
          // update a task [taskOrder]
          this.tasks = updatedTasks;
          // udpate the current taskGroupList with new task group
          let updatedTaskGroups = this.currentTaskGroups.map(tg => tg.taskGroupId === currentTaskGroup.taskGroupId ? { ...tg, tasks: this.tasks } : tg);
          // update model with updated task-group list
          let updatedModel: Model = {
            model: this.model,
            taskGroups: updatedTaskGroups,
            updated : false
          };
          // update model of package
          let updatedDraft: DraftTrainingPackage = this.packageUtilService.convertToDraftTrainingPackage(draft);
          if (updatedDraft.models != null) {
            const modelIndex = updatedDraft.models.findIndex(
              (existingModel) => existingModel.model === updatedModel.model
            );
            updatedDraft.models[modelIndex].taskGroups = updatedTaskGroups;
            // Return the updated draft for further processing
            return of(updatedDraft);
          }
          // If models is null or no matching model is found, return an empty observable
          return EMPTY;
        })
      )
      .subscribe((updatedDraft) => {
        // Notify the subscribers about the updated draft-training-package
        this.sharedDataService.updateCurrentEditDraftPackage(updatedDraft);

      });


    this.taskGroupName = '';
    action === 'add' ? this.toggleTgInputVisibility() : null;
  }
  
  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    this.taskComponentsVisibility.forEach((state: boolean, orderNumber: number) => {
      this.taskComponentsVisibility.set(orderNumber, false);
    });
    this.groupComponentVisibility.forEach((tskGrp, index) => {
      this.groupComponentVisibility[index] = false;
    });
  }

  handleDeleteTask(event: Event, task: Task) {
    // event.stopImmediatePropagation();
    this.taskComponentsVisibility.set(task.taskOrder, !this.taskComponentsVisibility.get(task.taskOrder));
    this.taskComponentsVisibility.forEach((state: boolean, orderNumber: number) => {
      if (orderNumber != task.taskOrder) {
        this.taskComponentsVisibility.set(orderNumber, false);
      }
    });
  }

  handleDeleteGroup(event: Event, taskgroup: TaskGroup) {
    event.stopImmediatePropagation();
   
    
    // console.log("taskgroup on visibilty check",this.isTaskGroupInputVisible)
    if (taskgroup.taskGroupOrder) {
      this.groupComponentVisibility[taskgroup.taskGroupOrder] = !this.groupComponentVisibility[taskgroup.taskGroupOrder];
      // this.isTaskGroupInputVisible = true;
    }
    
    this.groupComponentVisibility.forEach((tskGrp, index) => {
      if (index != taskgroup.taskGroupOrder) {
        this.groupComponentVisibility[index] = false;
      }
    });
  }

  istaskDeleteComponentVisible(task: Task) {
    return this.taskComponentsVisibility.get(task.taskOrder);
  }

  isgroupDeleteComponentVisible(taskgroup: TaskGroup) {
    if (taskgroup.taskGroupOrder) {
      return this.groupComponentVisibility[taskgroup.taskGroupOrder];
    }
    return false;
  }


  deleteTaskGroup(event: Event, index: number, taskGroup: TaskGroup) {
    event.stopImmediatePropagation();
    
    this.sharedDataService.updateEditCount(this.editCount);
    let taskGroupList = this.currentTaskGroups;
    if (taskGroup.status == 'new') {
      if (taskGroupList) {
        // group.tasks.splice(taskIndex, 1);
        console.log("first taskGroupList",taskGroupList);
        let updatedTaskGrp = taskGroupList.filter(taskgrp => taskgrp != taskGroup);
        taskGroupList = updatedTaskGrp;
        console.log("taskGroupList in deleteTaskGroup",taskGroupList);
        this.updateDeletedTaskGroup(event,index,taskGroupList);
        this.taskGroups = taskGroupList;
        this.sharedDataService.updateTaskGroups(taskGroupList);
      }
    }
    
      else if (taskGroupList) {
        // Find the task by taskId and update its action to 'delete'
       
        const deleteTskGrp = taskGroupList.find(taskgrp => taskgrp.taskGroupId == taskGroup.taskGroupId);
        console.log("delete-grp",deleteTskGrp)
        if (deleteTskGrp) {
          deleteTskGrp.action = 'delete';
          
            this.sharedDataService.updateTaskGroup(deleteTskGrp);
           
            console.log("tsk-grp-list", taskGroupList);
        } else {
            console.error('Task not found:', deleteTskGrp);
        }
        this.taskGroups = this.taskGroups.filter(taskgrp => taskgrp.action != "delete");
    }
    
    
    

  }


  deleteTask(event: Event, group: TaskGroup, Deletetask: any, taskIndex: number, groupIndex: number) {
    event.stopImmediatePropagation();
    console.log(this.currentTask)
    console.log("Delete task:", Deletetask)
    console.log("Current taskgroup:", group)
    this.sharedDataService.updateEditCount(this.editCount);
    // check if task status is 'new
    if (Deletetask.status === 'new') {
      if (group?.tasks) {
        // group.tasks.splice(taskIndex, 1);
        let updatedTasks = group.tasks.filter(task => task.taskId != Deletetask.taskId);
        group.tasks = updatedTasks;
        this.updateDeletedTask(event,group,updatedTasks)
        // this.sharedDataService.updateTaskGroup(group);

        console.log('group:',group)
        console.log('TP after delete task:', this.currentDraftPackage)
      }
    }
    else {
      if (group?.tasks) {
        // Find the task by taskId and update its action to 'delete'
        const task = group.tasks.find(task => task.taskId === Deletetask.taskId);
        if (task) {
            task.action = 'delete';
            this.sharedDataService.updateTaskGroup(group);
            console.log('TaskGroup after marking task as "delete":', this.currentDraftPackage);
        } else {
            console.error('Task not found:', Deletetask.taskId);
        }
    }
    }

    this.handleDeleteTask(event, Deletetask);


    if (this.currentTask?.taskId === Deletetask.taskId) {
      if (group?.tasks) {
        const filteredTasks = group.tasks.filter(task => task.action !== 'delete');
        if (filteredTasks.length === 0) {
          // If there is only one task in the group, route to a particular link
          this.router.navigate(['packages',this.trainingPackageId]);
        } else {
          this.currentTask = filteredTasks[0];
          this.sharedDataService.updateTask(this.currentTask);
          
        }

      }
    }
  }

  // update the task view as per selected task-group .


  renameTask(task: Task) {
    task.taskId = task.taskId || uuidv4();
    this.selectedTaskId = task.taskId;
  }

  renameTaskGroup(taskgroup: TaskGroup) {
    taskgroup.taskGroupId = taskgroup.taskGroupId || uuidv4();
    this.selectedTaskGroupId = taskgroup.taskGroupId;
  }

  updateTask() {
    this.sharedDataService.updateEditCount(this.editCount);
    this.selectedTaskId = '';
  }

}

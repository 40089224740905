<div>
    <div class="outer-section sticky-top">
        <div class="row">
            <div class="col-12">
                <label mat-dialog-title class="accept-reject-updat">Accept/Reject {{updatesvalue}} to Artifact</label>
                <div>
                    <p mat-dialog-title class="entity-all">
                        <!-- <span *ngIf="this.type=='CONTRACT'">Entity: {{this.entity}}<br /></span> -->
                        Entity:"All"<br />
                        Artifact ID: {{artifactIdHeader}}<br />
                        Artifact: {{currentObj!=null?currentObj.fileName:"N/A"}}<br />
                        Revision: {{revision}} <br />
                    </p>
                </div>
            </div>

        </div>

    </div>
    <div class=" close-div">
        <i class="material-icons-outlined close-icon" mat-dialog-close>close</i>
    </div>
    <mat-divider class="divider"></mat-divider>
    <div class="outer-table">
        <table class="artifact-approval-popup-table">
            <tr>
                <div >
                    <td class="middle-td">
                        <div class="d-flex flex-column" *ngIf="currentObj!=null">
                            <div class="current orange-text">{{status}}</div>
                            <span class="header-title">{{currentObj.title}}</span>
                            <div *ngIf="this.popupData.rowdata.current.artifactId!=='N/A' else na">
                                <span class="material-symbols-outlined desc-icon">
                                    description
                                </span>
                                <label class="doc-desc">{{currentObj.fileName}}</label>
                            </div>
                            <ng-template #na>
                                <div class="loader">N/A</div>
                            </ng-template>

                            <p class="text-data">{{currentObj.description}}</p>
                            <div *ngIf="this.popupData.rowdata.current.artifactId!=='N/A' else na">
                                <button class="btn-revision artifact-buttons-download"
                                    (click)="onClickDownloadArtifact(currentObj)">
                                    <!-- > -->
                                    <span class="material-symbols-outlined schedule-sym">download</span>
                                    DOWNLOAD
                                </button>
                            </div>
                            <ng-template #na>
                                <div class="loader">N/A</div>
                            </ng-template>
                        </div>
                    </td>
                    <td>
                        <div class="metadata-column">
                            <div class="metadata">META DATA
    
                            </div>
                            <!-- <div class="metadata-box" *ngFor="let metaData of artifactMetadata; index as metaDataIndex">
                            <div class="metadata-header">{{metaData.name}}</div>
                            <div>{{metaData.value}}</div>
                        </div> -->
    
                            <div *ngIf="currentArtifactMetaData && currentArtifactMetaData.metadataOrder; ">
                                <!-- else loader -->
                                <div class="metadata-box"
                                    *ngFor="let key of currentArtifactMetaData.metadataOrder; index as metaDataIndex">
                                    <div class="metadata-header">
                                        {{key}}</div>
                                    <div>{{currentArtifactMetaData.artifactMetaData[key]}}</div>
                                </div>
                            </div>
                            <!-- <ng-template #loader>
                                <div class="loader">Loading...</div>
                            </ng-template> -->
                        </div>
                    </td>
                </div>



            </tr>
            <!--         
        <tr>

           
            <td >
                <div class = "accept-reject-icons">
                    <i class="material-icons-outlined reject-icon">cancel</i>
                    <i class="material-icons-outlined accept-icon">check_circle</i>
                </div>
            </td>

        </tr> -->
            <!-- <mat-divider class="row-divider"></mat-divider> -->
            <tr>
                <!-- <mat-divider class="row-divider"></mat-divider> -->
                <div *ngFor="let data of tableData; ">
                    <div *ngFor="let update of allUpdateArtifacts; ">
                        <mat-divider class="row-divider"></mat-divider>
                        <div class="error-message">UPDATE {{update.modifiedAt | date:'yyyy-MM-dd hh:mm a' | lowercase }}</div>
                        <td class="middle-td">
                            <div class="d-flex flex-column">

                                <span class="header-title" *ngIf = "allUpdateArtifacts.length>0">{{update.title}}</span>
                                <div *ngIf = "allUpdateArtifacts.length>0">
                                    <span class="material-symbols-outlined desc-icon">
                                        description
                                    </span>
                                    <label class="doc-desc">{{update.fileName}}</label>
                                </div>
                                <p *ngIf = "allUpdateArtifacts.length>0"class="text-data">{{update.description}}</p>
                                <div *ngIf = "allUpdateArtifacts.length>0">
                                    <button class="btn-revision artifact-buttons-download"
                                        (click)="onClickDownloadArtifact(update)">
                                        <span class="material-symbols-outlined schedule-sym">download</span>
                                        DOWNLOAD
                                    </button>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="metadata-column">
                                <div class="metadata">META DATA
                                </div>
                                <div *ngIf="update.metadata && update.metadata.metadataOrder; else loader">
                                    <div class="metadata-box"
                                        *ngFor="let key of update.metadata.metadataOrder; index as metaDataIndex">
                                        <div class="metadata-header">
                                            {{key}}</div>
                                        <div>{{update.metadata.artifactMetaData[key]}}</div>
                                    </div>
                                </div>
                                <ng-template #loader>
                                    <div class="loader">Loading...</div>
                                </ng-template>
                            </div>
                        </td>
                        <td>
                            <div class="accept-reject-icons">
                                <i class="material-icons-outlined reject-icon"
                                    (click)="onRejectAction(data, update.artifactId, 'reject')">cancel</i>
                                <i class="material-icons-outlined accept-icon"
                                    (click)="onAcceptAction(data, update.artifactId, 'accept')">check_circle</i>
                            </div>
                        </td>
                    </div>
                </div>

            </tr>
            <!-- <mat-divider class="row-divider"></mat-divider> -->
        </table>
    </div>
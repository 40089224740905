import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApprovalTrainingPackage } from 'src/app/models/training-package.model';
import { TrainingPackageService } from 'src/app/services/training-package-service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay-service';
import { BtlSharedLibraryService } from 'btl-shared-library';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-package-approval',
  templateUrl: './package-approval.component.html',
  styleUrls: ['./package-approval.component.scss']
})
export class PackageApprovalComponent implements OnInit {

  columns: any[] = [
    // 'entity',
    // 'type',
    // 'title',
    // 'submitted',
    // 'accept'
  ];
  displayedColumns: string[] = ['entity','distribution','area','model','updates', 'view','submitted', 'accept'];
  dataSource :any;
  dataLoad = false;
  TableData: ApprovalTrainingPackage[] = [];
  user: string = "admin";
  page: number = 0;
  currentPage:any = "approval";
  pageSizeOptions: number[] = [5];
  size: number = 5;
  pageSize = 10;
  showPages = 2;
  pageLength = this.pageSize * (this.showPages - 1);
  constructor(private trainingPackageService: TrainingPackageService,
    private sharedDataService: SharedDataService,
    private router: Router,
    private btlSharedLibraryService: BtlSharedLibraryService,
    private spinnerOverlayService: SpinnerOverlayService) { }
    @ViewChild(MatPaginator) paginator?: MatPaginator;
  ngOnInit(): void {

    this.sharedDataService.currentPage$.subscribe((value)=>{
      this.currentPage = value;
    });
    this.loadData();
  }


  triggerPaginatorFalseClick() {
    this.paginator?.nextPage();
    this.paginator?.previousPage();
  }

  // pageNavigation(event: PageEvent) {
  //   this.page = event.pageIndex;
  //   this.size = event.pageSize;
  //   this.loadData();
  // }
  pageChangeEvent(event: PageEvent) {
    console.log("into pagechangeEvent");
    this.dataSource.paginator = this.paginator;
  }

  onAcceptAction(row: any, id:any) {
    this.spinnerOverlayService.show()
    console.log("pkg-rowdata",row,"trngpkg-id",id)
    const requestBody = {
      "trainingPackageIds": [id],
      "model": row.model
    };
    console.log(requestBody);
    this.trainingPackageService.approveTrainingPackage(requestBody, this.user)
      .subscribe(
        response => {
          console.log(' response:', response);
          this.spinnerOverlayService.hide();
          this.btlSharedLibraryService.updateStatusMessage(true, `${response.message}`, true)
          this.resetData(id)
        },
        error => {
          console.error('Error approving training Package:', error);
          this.spinnerOverlayService.hide();
          const errorMessage = error.error && error.error.message ? error.error.message : "An error occurred during the request.";
          this.btlSharedLibraryService.updateStatusMessage(true, errorMessage, false);
        },
        () => {
          this.spinnerOverlayService.hide();
        }
      );
  }

  onRejectAction(row: any, id:any) {
    this.spinnerOverlayService.show()
    const requestBody = {
      trainingPackageIds: [id],
      "model": row.model
    };
    console.log(requestBody);
    this.trainingPackageService.rejectTrainingPackage(requestBody, this.user)
      .subscribe(
        response => {
          console.log(' response:', response);
          this.spinnerOverlayService.hide();
          this.btlSharedLibraryService.updateStatusMessage(true, `${response.message}`, true)
          this.resetData(id);
        },
        error => {
          console.error('Error rejecting artifacts:', error);
          this.spinnerOverlayService.hide();
          const errorMessage = error.error && error.error.message ? error.error.message : "An error occurred during the request.";
          this.btlSharedLibraryService.updateStatusMessage(true, errorMessage, false);
        },
        () => {
          this.spinnerOverlayService.hide();
        }
      );
  }

  loadData(): void {
    this.spinnerOverlayService.show();
    this.trainingPackageService.getApprovalTrainingPackages("", this.page, this.size).subscribe({
      next: (data) => {
        console.log("all trainingPackage ::", data);
        if (data.status === '200 OK') {
          this.TableData = data.data;
          this.dataSource = new MatTableDataSource<ApprovalTrainingPackage>(this.TableData);
          // this.dataSource.paginator = this.paginator;
          this.pageLength = this.TableData.length;
          this.dataSource.paginator = this.paginator;
        }
        // this.spinnerOverlayService.hide();
      },
      error: (e) => {
        // this.spinnerOverlayService.hide();
        console.error(e);
        const errorMessage = e.error && e.error.message ? e.error.message : "An error occurred during the request.";
        console.log("Error ::", errorMessage);
      }
      
    })
    this.dataLoad = true;
  }

  showPreview(rowdata:any) {
    let taskGroup = rowdata.models[0].taskGroups[0];
    let task = taskGroup.tasks[0];
    let artifact = taskGroup.tasks[0].artifacts;
    console.log("artifact",artifact);
    this.sharedDataService.updateTask(task);
    this.sharedDataService.updateTaskGroup(taskGroup);
    this.sharedDataService.updateArtifacts(artifact);
    this.sharedDataService.updateTaskGroups(rowdata.models[0].taskGroups);
    console.log("rowdata-val",rowdata)
    this.sharedDataService.updateCurrentEditDraftPackage(rowdata);
    this.sharedDataService.updateSearchFilters(rowdata);
    this.sharedDataService.updatePage("approval");
    this.router.navigate(['packages',rowdata.trainingPackageId, 'taskGroup', taskGroup.taskGroupName, 'task', task.taskName, 'preview']);
    this.sharedDataService.updateEditPackageHeaders(false);
    this.sharedDataService.updateshowPreviewPackageHeaders(true);
    this.sharedDataService.setSideBarType('preview');
  }

  resetData(trainingPackageIds: string[]) {
    console.log(this.dataSource)
    this.spinnerOverlayService.show()
    console.log("pre", trainingPackageIds, this.TableData)

    this.TableData = this.TableData.filter((trainigPackage: any) => !trainingPackageIds.includes(trainigPackage.trainingPackageId))
    console.log("post", trainingPackageIds, this.TableData)

    this.dataSource = new MatTableDataSource<ApprovalTrainingPackage>(this.TableData);
    this.loadData();
    this.spinnerOverlayService.hide();

  }

}

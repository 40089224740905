import { Injectable } from '@angular/core';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DraftTrainingPackage,
  Model,
  Task,
  TaskGroup,
} from 'src/app/models/training-package.model';
import { User } from '../models/user.model';
import { ArtifactsService } from './artifacts.service';
@Injectable({
  providedIn: 'root',
})
export class PackageUtilService {
  validateSaveDraftPackage(currentEditDraftPackage: any): { isDraftValid: any; artifacts: any; } {
    throw new Error('Method not implemented.');
  }
  constructor(
    private router: Router,
    private sharedDataService: SharedDataService,
    private artifactsService: ArtifactsService
  ) {}
  initializeDraftPackage(): void {
    // init empty package structure
    let task: Task = {
      taskId: '',
      taskOrder: 0,
      taskName: '',
      taskDescription: '',
      artifacts: [],
      taskState: '',
      artifactOrder:[]
    };

    let taskGroups: TaskGroup = {
      tasks: [task],
      taskGroupId: '',
      taskGroupName: '',
      taskGroupOrder: 0,
    };

    let model: Model = {
      model: '',
      taskGroups: [taskGroups],
      updated : false
    };

    // get user info
    let user :User;
    let createdBy = "";
    this.sharedDataService.user$.subscribe((user_)=>{
      user = user_;
      if(user !=null){
        createdBy = user.email;
      }
    });

    let draftTrainingPackage: DraftTrainingPackage = {
      airlineEntity: '',
      area: '',
      distribution: '',
      title: '',
      models: [model],
      modifiedBy: null,
      trainingPackageId: '',
      createdBy: createdBy,
    };

    // map between filter type and corresponding property in draftTrainingPackage
    const filterMapping: { [key: string]: keyof DraftTrainingPackage } = {
      airlineEntity: 'airlineEntity',
      area: 'area',
      distribution: 'distribution',
      models: 'models',
    };
     let currentModel ="";
      // get all the selected value from search filters shared data
     this.sharedDataService.selectedValuesList$.subscribe((values)=>{
       
       values.forEach((filter) => {
         const property = filterMapping[filter.type];
         if (property !== undefined) {
           if (property === 'models') {
             const modelName = filter.value ?? '';
             currentModel = modelName;
             draftTrainingPackage[property] = [{ model: modelName, taskGroups: [] , updated : false }];
           }else {
            draftTrainingPackage[property] = filter.value ?? '';
          }
         }
       });
     });
     
     const identifiers = ['trngPackage',draftTrainingPackage.airlineEntity,draftTrainingPackage.distribution,draftTrainingPackage.area,currentModel,"new"];
     draftTrainingPackage.trainingPackageId = identifiers.join("_");
     draftTrainingPackage.title = identifiers.join("_");

     /** update shared data for side-bar and task-group component */
     let models=null;
     if(draftTrainingPackage.models!=undefined){
        models = draftTrainingPackage.models
     }
     if (models!=null){
       this.sharedDataService.updateTaskGroups(models[0].taskGroups);

     }
     this.sharedDataService.updateCurrentEditDraftPackage(draftTrainingPackage);
     this.sharedDataService.updateSearchFilters(draftTrainingPackage);
     this.sharedDataService.updateEditPackageHeaders(true);

    this.navigateToEditMode(identifiers.join('_'));
  }

  navigateToEditMode(trainingPackageId: string): void {
    this.router.navigate(['packages',trainingPackageId]).then(() => {})
  }

  convertToDraftTrainingPackage(sourcePackage: any): DraftTrainingPackage {
    // Map the properties from sourcePackage to DraftTrainingPackage
    return {
      airlineEntity: sourcePackage.airlineEntity || '',
      area: sourcePackage.area || '',
      createdBy: sourcePackage.createdBy || '',
      distribution: sourcePackage.distribution || '',
      title: sourcePackage.title || '',
      models: sourcePackage.models || [],
      modifiedBy: sourcePackage.modifiedBy || null,
      trainingPackageId: sourcePackage.trainingPackageId || '',
    };
  }

  sortTaskGroupsByOrder(taskGroups:TaskGroup[]): TaskGroup[] {
    if(taskGroups == null) {
      return [];
    }
    return taskGroups
      .filter(taskGroup => taskGroup != null) // Filter out null and undefined task groups
      .map(taskGroup => ({
        ...taskGroup,
        tasks: taskGroup?.tasks?.sort((a: any,b: any) => (a?.taskOrder || 0) - (b?.taskOrder || 0)) || []
      }))
      .sort((a: any,b: any) => (a?.taskGroupOrder || 0) - (b?.taskGroupOrder || 0));
    }

    removeAfterLastPattern(str:string, substring:string) {
      let index = str.lastIndexOf(substring);
      if (index !== -1) {
          return str.substring(0, index);
      }
      return str;
    }

    formatDate(dateStr:string){
      let date = new Date(dateStr);
      let timeString = date.toLocaleTimeString();
      return `${date.getMonth()+1}-${date.getDate()}-${date.getFullYear()}   ${timeString}`
    }

    getArtifactId(artifact:any):any{
      let modifiedArtifactId = artifact.artifactId;
      if(artifact.artifactId.includes('_APPROVAL_')){
        modifiedArtifactId= this.removeAfterLastPattern(artifact.artifactId, '_APPROVAL_');
      }else if(artifact.artifactId.includes('_IN_WORK_')){
        modifiedArtifactId= this.removeAfterLastPattern(artifact.artifactId, '_IN_WORK_');
      }
      return modifiedArtifactId;
    }

    // getMetaDataOrder(data:any,type:string):any{
    //   let metadataOrder =   ['Course Type', 'Updated', 'Regulator', 'Document Type', 'Customer Code'];
    //   let artifactMetaData:any = {
    //     'Course Type': data.metadata.courseType,
    //     'Updated': this.formatDate(data.cmsObjectInfo.modifiedDate),
    //     'Regulator': data.metadata.regulatoryAgency,
    //     'Document Type':data.metadata.documentType,
    //     'Customer Code': data.metadata.customerCode
    //   };

    //   if(type === 'approval'){
    //     metadataOrder.push('Revision');
    //     artifactMetaData = { ...artifactMetaData, 'Revision': data.cmsObjectInfo.documentVer };
    //   }
      
    //   return {
    //     metadataOrder:metadataOrder,
    //     artifactMetaData: artifactMetaData
    //   }
    // }

    getMetaDataOrder(data: any, type: string): any {
      let metadataOrder = ['Course Type', 'Updated', 'Regulator', 'Document Type', 'Customer Code'];
      let artifactMetaData: any = {
          'Course Type': data?.metadata?.courseType || '',
          'Updated': data?.cmsObjectInfo ? this.formatDate(data.cmsObjectInfo.modifiedDate) : '',
          'Regulator': data?.metadata?.regulatoryAgency || '',
          'Document Type': data?.metadata?.documentType || '',
          'Customer Code': data?.metadata?.customerCode || ''
      };
  
      if (type === 'approval') {
          metadataOrder.push('Revision');
          artifactMetaData = { ...artifactMetaData, 'Revision': data?.cmsObjectInfo?.documentVer || '' };
      }
  
      return {
          metadataOrder: metadataOrder,
          artifactMetaData: artifactMetaData
      };
    }
  

    async appendMetadataToArtifact(artifacts: any,type:string): Promise<any[]> {    
      let artifactIds: string[] = [];
      let updatedArtifacts: any[] = [];
      let metadata: any[]; 

      // Check if metadata needs to be fetched for any artifact
      const fetchMetadata = artifacts.some((artifact: any) => !artifact.metadata);
      if (!fetchMetadata) {
        // No need to fetch metadata, return artifacts as it is.
        return artifacts;
      }

      // Extract artifactIds and prepare for metadata fetching
      artifacts.forEach((artifact: any) => {
        let modifiedArtifactId = this.getArtifactId(artifact);
        artifactIds.push(modifiedArtifactId);
      });
    
      try {
        // Fetch metadata asynchronously
        metadata = await this.getArtifactMetadata(artifactIds);
    
        // Update artifacts with metadata
        artifacts.forEach((ar: any) => {
          let contentKey = this.getArtifactId(ar);
          let artifactWithMetadata = metadata.filter(m => m.contentKey === contentKey);
          let response = artifactWithMetadata[0] === undefined ? null : artifactWithMetadata[0];
          let data = this.getMetaDataOrder(response,type);
          ar.metadata = data;
          updatedArtifacts.push(ar);
        });    
        console.log("Artifacts With metadata : ", updatedArtifacts);
      } catch (error) {
        console.error('Error fetching metadata for artifacts:', error);
      }
      return updatedArtifacts;
    }
    
    // fetch metadata asynchronously
    getArtifactMetadata(artifactIds: string[]): Promise<any[]> {
      let allMetadataResults: any[] = [];
      return new Promise((resolve, reject) => {
        this.artifactsService.getArtifactMetadata(artifactIds).subscribe(
          (data: any) => {
            let allMetadataResults: any[] = data.data;
            resolve(allMetadataResults);
          },
          (error: any) => {
            console.error('Error fetching metadata for artifacts:', error);
            resolve(allMetadataResults);
          }
        );
      });
    }
    
}

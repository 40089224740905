import { Component, OnInit } from '@angular/core';
import { ActivatedRoute ,Router,NavigationEnd} from '@angular/router';
import { SharedDataService } from 'src/app/services/shared-data.service';

@Component({
  selector: 'app-training-package-details',
  templateUrl: './training-package-details.component.html',
  styleUrls: ['./training-package-details.component.scss']
})
export class TrainingPackageDetailsComponent implements OnInit {
  trainingPackageId: string | null = null;
  rowData: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sharedDataService:SharedDataService,
    ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Scroll to the top of the page
        
        window.scrollTo(0, 0);
      }
    });
    this.sharedDataService.rowData$.subscribe(pkg => {
      this.rowData = pkg;
    })
    this.sharedDataService.updateModelFilters(this.rowData);
    this.sharedDataService.updateTaskGroups(this.rowData.taskgroup);
    this.sharedDataService.updateEditPackageHeaders(true);
    // Retrieve the trainingPackageId from the route parameters
    this.route.paramMap.subscribe((params) => {
      this.trainingPackageId = params.get('trainingPackageId');
      
      // Fetch additional details based on the trainingPackageId if needed
    });
  }



}

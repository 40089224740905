<table mat-table [dataSource]="dataSource" class="artifact-approval-table">


    <ng-container matColumnDef="entity">
        <th mat-header-cell *matHeaderCellDef> Entity </th>
        <td mat-cell class="entity" *matCellDef="let element"> {{element.entity}} </td>
    </ng-container>

    <ng-container matColumnDef="distribution">
        <th mat-header-cell *matHeaderCellDef> Distribution </th>
        <td mat-cell class="entity" *matCellDef="let element"> {{element.distribution}} </td>
    </ng-container>

    <ng-container matColumnDef="area">
        <th mat-header-cell *matHeaderCellDef> Area </th>
        <td mat-cell class="entity" *matCellDef="let element"> {{element.area}} </td>
    </ng-container>

    <ng-container matColumnDef="model">
        <th mat-header-cell *matHeaderCellDef> Model </th>
        <td mat-cell class="entity" *matCellDef="let element"> {{element.model}} </td>
    </ng-container>

    <ng-container matColumnDef="updates">
        <th mat-header-cell *matHeaderCellDef> Updates </th>
        <td mat-cell class="updates-cell" *matCellDef="let element">
            
            <div *ngIf="element.packages.current!== null; ">
                <label class="current-pkg">CURRENT</label>
                
                <div class="modified-by-div-current">{{element.packages.current.createdBy}}</div>
            </div>
            <label class="pkg-updateslabel">{{element.status | uppercase}} </label> <br>
            
           
            <ng-template #noCurrentData>
                <div class="loader">N/A</div>
            </ng-template>
            <ng-container *ngFor="let pkg of element.packages.approvals">
                
                <div class="modified-by-div">{{pkg.createdBy}}</div>
            </ng-container>
        </td>
        
    </ng-container>

    <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef> View </th>
        <td mat-cell *matCellDef="let element" class="viewcell">
            <div *ngIf="element.packages.current!== null">
                <div class="modified-by-div-current-view" >
                    <mat-icon class="material-icons-outlined view pointer"(click)="showPreview(element.packages.current)">visibility</mat-icon> <br>
                </div>
            </div>
            <ng-container *ngFor="let pkg of element.packages.approvals">
                <div class="modified-by-div-view" >
                    <mat-icon class="material-icons-outlined view pointer" (click)="showPreview(pkg)">visibility</mat-icon> <br>
                </div>
            
            </ng-container>
            

        </td>
    </ng-container>

    <ng-container matColumnDef="submitted">
        <th mat-header-cell *matHeaderCellDef> Submitted(PST) </th>
        <td mat-cell *matCellDef="let element" class="submitted-cell">
            <div *ngIf="element.packages.current!== null">
                <div class="submitted-part-current" style="padding-top: 20px;">
            </div>
            </div>
            <ng-container *ngFor="let pkg of element.packages.approvals">
                <div class="submitted-part">{{ pkg.createdAt | date:'yyyy-MM-dd hh:mm a'| lowercase
                    }}
                </div>
               
            </ng-container>
             </td>

    </ng-container>

    <ng-container matColumnDef="accept">
        <th mat-header-cell *matHeaderCellDef> Accept </th>
        <td mat-cell *matCellDef="let row" class="accept-reject-cell">
            <div *ngIf="row.packages.current!== null">
                <!-- class="submitted-part-current -->
                <div class="actions-current "  >
            </div>
            </div>
            <ng-container *ngFor="let pkg of row.packages.approvals">
                <div class="d-flex actions">
                    <i class="material-icons-outlined reject-icon" (click)="onRejectAction(row,pkg.trainingPackageId)">cancel</i>
                    <i class="material-icons-outlined accept-icon" (click)="onAcceptAction(row,pkg.trainingPackageId)">check_circle</i>
                </div>
            </ng-container>
        </td>
    </ng-container>




    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator *ngIf="dataLoad" (page)="pageChangeEvent($event)" [length]="pageLength" [pageSize]="pageSize"
    [showTotalPages]="3" style-paginator [pageSizeOptions]="[3, 5, 10, 20]">
</mat-paginator>
<div cdkDropList [cdkDropListData]="artifacts" class="artifact-drop-list" (cdkDropListDropped)="drop($event)">
    <div *ngFor="let artifact of artifacts ; trackBy: trackByArtifact; let i = index; "
        [ngClass]="{'selected-item': i === selectedIndex}">
        <div class="artifact-drop-box" *ngFor="let table_number of tables" cdkDrag [cdkDragDisabled]="isInputFocused">
            <div class="seperator"></div>
            <table [ngClass]="'draft-tbl'" [id]="'draft-tbl'+table_number">
                <tr>
                    <td class="horizontal-lines-td">
                        <mat-icon class="horizontal-lines-icon">drag_handle</mat-icon>
                    </td>
                    <td class="middle-td">
                        <div class="d-flex flex-column">
                            <div class="task-group-desc-div mb-3">
                                <span class="task-group-desc-title">Linked Artifact</span>
                                <div class="pb-2"><span class="task-group-desc-char-count"><strong>|</strong></span>
                                    <input class="artifact-input" value="{{artifact.fileName}}" (mouseenter)="onInputFocus()" (mouseleave)="onInputBlur()" (focus)="onInputFocus()" (blur)="onInputBlur()"/>
                                </div>
                            </div>
                            <div class="task-group-desc-div mb-3">
                                <span class="task-group-desc-title">Title*</span>
                                <div class="pb-2"><span class="task-group-desc-char-count"><strong>|</strong></span>
                                    <input class="artifact-input" [(ngModel)]="artifact.title"
                                        (blur)="onArtifactUpdate(artifact,'update')"
                                        [ngClass]="{ 'error-input': artifactTitleError && !artifact.title }"
                                        #artifactInput (focus)="onInputFocus()" (mouseenter)="onInputFocus()" (mouseleave)="onInputBlur()"/>
                                    <div *ngIf="artifactTitleError && !artifact.title" class="error-message">Title is
                                        required.</div>
                                </div>
                            </div>
                            <div class="task-group-desc-div">
                                <span class="task-group-desc-title">Description (Optional)</span>
                                <!-- <span class="task-group-desc-char-count"><strong>|</strong>{{artifact?.description ?
                                    (this.maxCharCount - this.descriptionValue.length):
                                    this.maxCharCount}}/{{this.maxCharCount}} characters left</span> -->
                                <textarea class="task-group-desc-textarea" [(ngModel)]="artifact.description"
                                    (blur)="onArtifactUpdate(artifact,'update')" (focus)="onInputFocus()" (mouseenter)="onInputFocus()" (mouseleave)="onInputBlur()"></textarea>
                            </div>

                            <div class="artifact-buttons-div">
                                <button class="btn-revision artifact-buttons"
                                    (click)="onClickDownloadArtifact(artifact)">
                                    <span class="material-symbols-outlined schedule-sym">download</span>
                                    DOWNLOAD
                                </button>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="mx-2">
                            <div class="artifact-information">META DATA
                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon class="material-symbols-outlined more-vert-sym delete-task-vert">more_vert
                                    </mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="deleteArtifact(artifact,i)">
                                        <mat-icon>delete</mat-icon>
                                        <span>Delete</span>
                                    </button>
                                </mat-menu>
                            </div>
                              <div class="metadata-box">
                                   <mat-icon class="material-icons-outlined fresh-icon" *ngIf="artifact.fresh">
                                    eco
                                  </mat-icon> 
                                <div class="metadata-header">Revision</div>
                             
                                <select [(ngModel)]="selectedRevisions[i]" (ngModelChange)="updateArtifactRevision(artifact, $event,i)" 
                                [ngClass]="{'mat-select-fresh':artifact.fresh==true}">
                                   <option *ngFor="let revision of getRevisionsForArtifact(artifact)" 
                                   [value]="revision.artifactId.split(':')[1]">{{revision.artifactId.split(':')[1]}}</option>
                                </select>
                            </div>
                            <!-- <div class="metadata-box" *ngFor="let metaData of artifactMetadata; index as metaDataIndex">
                                <div class="metadata-header">{{metaData.name}}</div>
                                <div>{{metaData.value}}</div>
                            </div> -->
                            <div *ngIf="artifact.metadata && artifact.metadata.metadataOrder; else loader">
                                <div class="metadata-box" *ngFor="let key of artifact.metadata.metadataOrder; index as metaDataIndex">
                                    <!-- <div *ngIf="artifact.metadata.artifactMetaData[key]" class="metadata-header">{{key}}</div> -->
                                    <div class="metadata-header">{{key}}</div>
                                    <div>{{artifact.metadata.artifactMetaData[key]}}</div>
                                </div>
                            </div>
                            <ng-template #loader>
                                <div class="loader">Loading...</div>
                            </ng-template>
                        </div>
                        <!-- <button class="btn-revision">
                            <span class="material-symbols-outlined schedule-sym">schedule</span>
                            REVISION
                            <span class="material-symbols-outlined expand-sym">expand_more</span>
                        </button> -->
                    </td>
                </tr>
            </table>
        </div>
    </div>

</div>
<section class="outer-section responsive">
    <div class = "header" >
        <h2 class="title-header">Approve {{headerText}} Updates</h2>
        <div class = "d-flex actions-div" *ngIf=isVisible (click)="navigateToEditor()" >
            <button  class="btn-navigate-editor" >Navigate to Editor</button>
         
          <mat-icon class="material-icons-outlined chevron-right-editor">chevron_right</mat-icon>
          </div>
    </div>
    
    
    <!-- <div class="tab-right-buttons">
        <button class="btn-approve-selected" (click)="approveSelected()"><i class="material-icons-outlined">check_circle</i><span>APPROVE
                SELECTED</span></button>
        <mat-checkbox class="btn-select-all" [(ngModel)]="selectAllCheckboxValue"
            (change)="onSelectAllCheckboxChange()">
            <span>SELECT ALL</span>
        </mat-checkbox>
    </div> -->
    <mat-tab-group #tabGroup mat-align-tabs="start" [color]="'warn'" [(selectedIndex)]="activeTabIndex" (selectedTabChange)="tabChanged(tabGroup.selectedIndex)"
        >
        <mat-tab label="ARTIFACTS">
            <app-artifact-approval></app-artifact-approval>
        </mat-tab>
        <mat-tab label="PACKAGES">
            <app-package-approval></app-package-approval>
        </mat-tab>
    </mat-tab-group>
</section>
import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TrainingPackageService } from '../../services/training-package-service'
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { Model, TaskGroup, Task } from 'src/app/models/training-package.model';
import { PackageUtilService } from 'src/app/services/package-util.service';
import { SharedService } from 'src/app/services/shared.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ArtifactsApprovalViewPopupComponent } from '../artifacts-approval-view-popup/artifacts-approval-popup.component';
import { ConfirmationDialogComponent } from '../packages/confirmation-dialog/confirmation-dialog.component';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay-service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  title = 'btl-training-package-ui';
  items = ['Carrots', 'Tomatoes', 'Onions', 'Apples', 'Avocados'];
  basket = ['Oranges', 'Bananas', 'Cucumbers'];
  form: FormGroup | undefined;
  panelOpenState: boolean = false;
  showArtifactApprovalSideBar: boolean = false;
  sidebarType: string = "";
  selectedEntity: string | null = null;
  previousEntity: string | null = null;
  entityOptions: any[] = [];

  selectedDistribution: string | null = null;
  previousDistribution: string | null = null;
  distributionOptions: string[] = [];

  selectedModel: any | null = null;
  previousModel: any | null = null;
  modelOptions: any[] = [];

  selectedArea: string | null = null;
  previousArea: string | null = null;
  areaOptions: string[] = [];

  taskGroups: any[] = [];
  draftPackage: any;
  isEditModeActive: boolean = false;
  editCount: number = 0;
  draftPackages: any[] | null = null;
  currentDraftPackage: any[] | null = null;
  selectedTask: any;
  selectedValues: any;
  filterJson: any;
  models: any[] = [];
  currentTask!: Task;
  currentTaskGroup!: TaskGroup;
  // currentPage:any ="editor";
  
  constructor(
    private trainingPackageService: TrainingPackageService,
    private _router: Router,
    private sharedDataService: SharedDataService,
    private packageUtilService: PackageUtilService,
    private sharedService: SharedService,
    public dialog: MatDialog,
    private snipperOverlayService: SpinnerOverlayService,
    private router: Router,

  ) { }

  ngOnInit(): void {
    this.getCurrentUser();

    this.loadDraftPackageDetails();
    this.sharedDataService.resetEditCount();
    // subscribe to edit count
    this.sharedDataService.editCount$.subscribe(count => {
      this.editCount = count;
    })

    this.sharedDataService.selectedValues$.subscribe(selectedValues => {
    
      this.selectedValues = selectedValues;
      const filteredValues = { ...selectedValues };
      if ('models' in filteredValues) {
        delete filteredValues['models'];
      }
      this.filterJson = { ...this.filterJson, ...filteredValues };
    })


    this.sharedDataService.loadDraftPackages$.subscribe(loadDraftPackage => {
      if (loadDraftPackage) {
        this.getDraftTrainingPackage();
      }
    });

    



    // subscribe to lookup data; avoid api calls on page reload
    this.sharedDataService.lookupData$.subscribe(lookupdata => {
      if (lookupdata == null) {
        // api call 
        this.loadLookUpData();
      } else {
        this.setLookUpdata(lookupdata);
      }
    })



    // subscribe to all-draft-packages
    this.sharedDataService.draftPackages$.subscribe(packages => {
      this.draftPackages = packages;
      if (packages != null) {
        this.previousModel = packages[0].models;
      }
    })

    this.sharedDataService.currentModel$.subscribe(model => {
      
      this.previousModel = model;
      console.log("previous-model-current-model",this.previousModel);
    })

    this.sharedDataService.sidebarType$.subscribe(sidebarType => {
      this.sidebarType = sidebarType;
    })

    this.sharedDataService.currentEditDraftPackage$.subscribe(currentDraftPackage => {
      this.currentDraftPackage = currentDraftPackage;
      
    })
    this.sharedDataService.currentTask$.subscribe(task => {
      this.currentTask = task;
    })
    this.sharedDataService.currentTaskGroup$.subscribe(taskGroup => {
      this.currentTaskGroup = taskGroup;
    })



  }

  getCurrentUser() {
    let current_user = this.sharedService.getUserInfo();
    this.sharedDataService.updateUser(current_user);
    this.filterJson = { "createdBy": current_user.email }
  }




  loadDraftPackageDetails() {
    this.sharedDataService.taskGroups$.subscribe((taskGroups) => {
      this.taskGroups = taskGroups;
    });

    this.sharedDataService.searchFilters$.subscribe((filters) => {
      if (filters != null) {
        
        this.selectedEntity = filters.entity;
        this.selectedDistribution = filters.distribution;
        this.selectedArea = filters.area;
        this.selectedModel = filters.models;
        
       
        
        
      }
    });

    this.sharedDataService.currentEditDraftPackage$.subscribe((draftPackage) => {
      console.log("currentedit pkg",draftPackage);
      this.draftPackage = draftPackage;
    });

    this.sharedDataService.activateCreateButton$.subscribe((isEditModeActive) => {
      this.isEditModeActive = isEditModeActive;
    });
    if (this.taskGroups != null) {
      this.setTaskGroupAndTaskView();
    }
  }

  loadLookUpData() {
    this.trainingPackageService.getTpLookUpValues().subscribe({
      next: (data) => {

        if (data.status === '200 OK') {
          this.sharedDataService.updateLookupData(data.data);
          this.setLookUpdata(data.data);
        }
      },
      error: (e) => {
        console.error(e);
        const errorMessage = e.error && e.error.message ? e.error.message : "An error occurred during the request.";
        console.error('Lookup API Error Message:', errorMessage);
      }
    })
  }

  setLookUpdata(lookUpResponse: any) {

    this.entityOptions = lookUpResponse.airlineEntities;
    this.distributionOptions = lookUpResponse.distributions;
    this.areaOptions = lookUpResponse.areas;
    this.modelOptions = lookUpResponse.models.map((m: any) => m.modelName);

  }



  onFieldChange(type: string) {
    if (this.editCount === 0) {
      //If NO UNSAVED CHANGES are present
      switch (type) {
        case 'models':
          this.previousModel = this.selectedModel;
          this.sharedDataService.updateCurrentModel(this.selectedModel);
          this.sharedDataService.updateSelectedValue(type, this.selectedModel);
          // this.isEditModeActive ? this.addModelToDraftPackage() : null;
          this.filterTaskGroupsByModel();
          
          break;

        case 'airlineEntity':
          this.previousEntity = this.selectedEntity;
          this.sharedDataService.updateSelectedValue(type, this.selectedEntity);
          break;
        case 'distribution':
          this.previousDistribution = this.selectedDistribution;
          this.sharedDataService.updateSelectedValue(type, this.selectedDistribution);
          break;
        case 'area':
          this.previousArea = this.selectedArea;
          this.sharedDataService.updateSelectedValue(type, this.selectedArea);
          break;
      }
    }
    else {
      //If UNSAVED CHANGES are present
      const dialogRef = this.dialog.open(ConfirmationDialogComponent);
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result.result === 'proceed') {
          //Clicked on PROCEED
          this.sharedDataService.resetEditCount();
          switch (type) {
            case 'models':
              this.addModelToDraftPackage();
              this.openDraftPackage(this.draftPackage);
              break;
            case 'airlineEntity':
              this.sharedDataService.updateSelectedValue(type, this.selectedEntity);
              break;
            case 'distribution':
              this.sharedDataService.updateSelectedValue(type, this.selectedDistribution);
              break;
            case 'area':
              this.sharedDataService.updateSelectedValue(type, this.selectedArea);
              break;
          }

        } else {
          //Clicked on CANCEL or Clicked Away
          switch (type) {
            case 'models':
              this.selectedModel = this.previousModel;
              console.log("previous-model-cancel",this.previousModel);
              break;
            case 'airlineEntity':
              this.selectedEntity = this.previousEntity;
              break;
            case 'distribution':
              this.selectedDistribution = this.previousDistribution;
              break;
            case 'area':
              this.selectedArea = this.previousArea;
              break;
          }
        }
      });
    }
  }

  filterDraftPackages(): void {

    let currentFilters = {};
    this.sharedDataService.selectedValues$.subscribe(selectedFilters => {
      currentFilters = selectedFilters;
    })
    let filteredPackages;

    Object.entries(currentFilters).forEach(([filterBy, filterValue]) => {
      filteredPackages = this.draftPackages?.filter(draft => draft[filterBy] === filterValue);
    });

    console.log("filteredPackages",filteredPackages);

    this.sharedDataService.updateDraftPackages(filteredPackages);
  }

  filterTaskGroupsByModel(): void {
    console.log("draft pkgs in filter task group",this.draftPackage);
    if (this.selectedModel !== null && this.draftPackage != null) {
      const model = this.draftPackage.models.find((m: any) => m.model === this.selectedModel);
      this.taskGroups = model ? model.taskGroups : [];
      console.log("taskgrp",this.taskGroups)
      const sortedTaskGroups = this.packageUtilService.sortTaskGroupsByOrder(this.taskGroups);
      this.taskGroups = sortedTaskGroups;
      this.setTaskGroupAndTaskView();
    }
  }

  setTaskGroupAndTaskView() {
    // update the task view as per selected task-group .
    // set first task-group & task in view.
    console.log("draft pkgs in setTaskGroup",this.draftPackage);
    console.log("setTaskGroupAndTaskView",this.draftPackages);
    if (this.taskGroups.length > 0 && this.taskGroups[0].tasks.length > 0) {
      console.log("taskgrp",this.taskGroups);
      let taskGroup = this.taskGroups[0];
      let task = taskGroup.tasks[0]
      this.sharedDataService.updateTask(task);
      this._router.navigate(['packages',this.draftPackage.trainingPackageId, 'taskGroup', taskGroup.taskGroupName, 'task', task.taskName]);
    }
    
  }

  addModelToDraftPackage(): void {
    console.log("draft pkgs in add model",this.draftPackage);
    if (this.selectedModel !== null && this.draftPackage !== null) {
      // Check if the model already exists
      const modelExists = this.draftPackage.models.some((m: Model) => m.model === this.selectedModel);
      if (!modelExists) {
        // Create new model
        const newModel: Model = {
          model: this.selectedModel,
          taskGroups: [],
          updated : false
        };

        // Update package with new model
        this.draftPackage.models.push(newModel);

        // Update shared data for subscribers
        console.log("model in side bar addModelToDraftPackage",this.selectedModel);
        this.sharedDataService.updateCurrentDraftData(this.draftPackage, this.selectedModel);
        // this.sharedDataService.updateEditCount(this.editCount);
        this._router.navigate(['packages', this.draftPackage.trainingPackageId]);

      } else {

      }
    }
  }
  openTaskView(taskGroup: TaskGroup, task: Task): void {
    this.sharedDataService.updateTask(task);
    this.sharedDataService.updateTaskGroup(taskGroup);
    this.sharedDataService.updateArtifacts(task.artifacts)
  }


  getDraftTrainingPackage() {
    this.snipperOverlayService.show();
    
    this.trainingPackageService.getDraftTrainingPackages(this.filterJson, 0, 1).subscribe({
      next: (data) => {
       
        if (data.status === '200 OK') {
          if (data.data.length > 0) {
            
            
            this.previousModel = this.selectedModel;
            this.draftPackage = data.data[0];
            this.addModelToDraftPackage();
            this.openDraftPackage(data.data[0]);
          }
          else {
            console.log("getDraftTrainingPackage-else");
            this.trainingPackageService.getApprovedTrainingPackages(this.filterJson, 0, 1).subscribe({
              next: (data) => {

                console.log("getDraftTrainingPackage-else-approved training packages",data);
                
                if (data.status === '200 OK') {
                  if (data.data.length > 0) {
                    this.previousModel = this.selectedModel;
                   
                    this.openDraftPackage(data.data[0]);
                  }
                  this.snipperOverlayService.hide();
                }
              },
              error: (e) => {
                console.error(e);
                this.snipperOverlayService.hide();
              },
            });
          }
          this.snipperOverlayService.hide();
        }
      },
      error: (e) => {
        console.error(e);
        this.snipperOverlayService.hide();
      },
    });
  }

  openDraftPackage(draftPackage: any) {
    console.log("open Draft Pkgs",this.selectedModel)
    // update the search filters as per current draft-package   
    this.sharedDataService.updateActivateCreateButton(true);
    let models = draftPackage.models.filter((x : any)=> x.model ==  this.selectedModel)
    
    if(models == null ){
      this.taskGroups = this.selectedModel.taskGroups;
      
      this.sharedDataService.updateCurrentModel(this.selectedModel)
    }else{
      this.taskGroups = models[0].taskGroups;
      
      this.sharedDataService.updateCurrentModel(models)
    }
    
    this.sharedDataService.updateCurrentEditDraftPackage(draftPackage);
    

    // re-order task-groups & update task-groups.
    this.sharedDataService
      .updateTaskGroups(
        this.packageUtilService.sortTaskGroupsByOrder(this.taskGroups)
      );

    // this.sharedDataService.updateSearchFilters(draftPackage);

    // enable edit mode
    this.sharedDataService.updateEditPackageHeaders(true);

    // navigate to edit-draft-mode
    this.router.navigateByUrl('packages',draftPackage.trainingPackageId).then(() => {
    });

    // navigate to task-view if present
    this.setTaskGroupAndTaskView();
  }
}


import { Injectable } from '@angular/core';
import { CheckAccessModel } from '../models/check-access.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SharedService } from './shared.service';
import { User } from '../models/user.model';
import { Router } from '@angular/router';
import { catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private router: Router, private sharedService: SharedService) { }

  getUserInfo(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.http.get<CheckAccessModel>(environment.checkAccessUrl).pipe(
          catchError(error => of(error))
        ).subscribe((response) => {
          if (response?.statusMessage === "authorized") {
            this.sharedService.setAdGroups(response.adGroups)
            this.sharedService.setUserInfo(response?.userInfo);
          }
          resolve();
        })
      } catch (error) {
        this.router.navigate(["unauthorized"]);
        reject(error);
      }
    })
  }
}

<div class="training-package-list">
  <div class = "d-flex actions-div" >
    <p class="draftpkg-header">Training Package Editor</p>
    <div class = "d-flex actions-div" *ngIf=isVisible (click)="navigateToApprovals()" >
      <button  class="btn-navigate-approval" >Navigate to Approvals</button>
   
    <mat-icon class="material-icons-outlined chevron-right-approval">chevron_right</mat-icon>
    </div>
      
</div>
   <div class="draftpkg-editor">
    <p class>Create a new or edit an existing training package by 
      filtering in the left side navigation bar.</p>
      <p>Edit or view one of your saved draft training packages by selecting from the table below.</p>
      <p class="drafts">Training Package Drafts</p>
    </div> 
    
</div>

<table mat-table [dataSource]="dataSource" matSort matSortDirection="asc" class="artifact-approval-table">


  <ng-container matColumnDef="entity">
  
      <th mat-header-cell *matHeaderCellDef> Entity </th>
      <td mat-cell *matCellDef="let element" class=" artifact"> {{element.airlineEntity}} </td>
  </ng-container>

  <ng-container matColumnDef="distribution">
      <th mat-header-cell *matHeaderCellDef> Distribution </th>

      <td mat-cell *matCellDef="let element" class=" distribution"> {{element.distribution}} </td>
  </ng-container>

  <ng-container matColumnDef="area">
    <th mat-header-cell *matHeaderCellDef> Area</th>
    <td mat-cell *matCellDef="let element" class="area"> {{element.area}} </td>
</ng-container>

  <ng-container matColumnDef="model">
      <th mat-header-cell *matHeaderCellDef> Model </th>
      <td mat-cell *matCellDef="let element" class=" model"> {{element.model}} </td>
  </ng-container>


  <ng-container matColumnDef="lastupdated">
      <th mat-header-cell *matHeaderCellDef> LastUpdated(PST) </th>
      <td mat-cell *matCellDef="let element" class="fieldcell"> 
      <div class="submitted-part">{{element.modifiedAt | date:'yyyy-MM-dd'}}</div>
                <br>
            <div class="submitted-time-part">{{ element.modifiedAt | date:'hh:mm a' | lowercase }}</div>
            <br>
        </td>
  </ng-container>

  <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions </th>
      <td mat-cell class="action"*matCellDef="let element">
         
          <div class="d-flex actions-div">
              <i class="material-icons-outlined  edit_icon" (click)="editMode(element)" >edit</i>
              <mat-icon class="material-icons-outlined view pointer" (click)="showPreview(element)">visibility</mat-icon>
          </div>
          <div class ="empty_Accept_Reject">   </div>
     
      </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator *ngIf="dataLoad" (page)="pageChangeEvent($event)" [length]="pageLength" [pageSize]="pageSize"
 [showTotalPages] = "3"
 paginator [pageSizeOptions]="[3, 5, 10, 20]">
</mat-paginator>
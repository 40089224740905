import { Routes } from '@angular/router';
import { TrainingPackageDetailsComponent } from './components/packages/training-package-details/training-package-details.component';
import { TrainingPackageComponent } from './components/packages/training-package/training.package.component';
import { LayoutComponent } from './components/layout/layout.component';
import { DraftArtifactSectionComponent } from './components/draft-artifact-section/draft-artifact-section.component';
import { ArtifactApprovalParentComponent } from './components/artifact-approval-parent/artifact-approval-parent.component';
import { PreviewComponent } from './components/preview/preview.component';
import { AuthGuard } from './services/auth.guard';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { HomeComponent } from './components/home/home.component';

const routes: Routes = [
  {
    path: 'approvals',
    component: ArtifactApprovalParentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'packages',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: TrainingPackageComponent,
      },
      {
        path: ':trainingPackageId',
        component: TrainingPackageDetailsComponent,
      },
      {
        path: ':trainingPackageId/taskGroup/:taskGroup/task/:task',
        component: DraftArtifactSectionComponent,
      },
      {
        path: ':trainingPackageId/taskGroup/:taskGroup/task/:task/preview',
        component: PreviewComponent,
      },
    ],
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
  }
];

export { routes };

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BtlSharedLibraryService } from 'btl-shared-library';
import { Artifact } from 'src/app/models/training-package.model';
import { ArtifactsService } from 'src/app/services/artifacts.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay-service';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import { ArtifactsApprovalViewPopupComponent } from '../artifacts-approval-view-popup/artifacts-approval-popup.component';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-artifact-approval',
  templateUrl: './artifact-approval.component.html',
  styleUrls: ['./artifact-approval.component.scss']
})
export class ArtifactApprovalComponent implements OnInit {

  columns: any[] = [
    // 'artifactid',
    // 'title',
    // 'submitted',
    // 'accept'
  ];
  displayedColumns: string[] = ['artifactId','view', 'field', 'title', 'update','submitted', 'accept'];
  dataSource : any;
  dataLoad = false;
  TableData: Artifact[] = [];
  user: string = "admin";
  page: number = 0;
  pageSizeOptions: number[] = [10];
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  fieldType: string ='';
  pageSize = 10;
  showPages = 2;
  pageLength = this.pageSize * (this.showPages - 1);
 


  constructor(private artifactsService: ArtifactsService,
    private sharedDataService: SharedDataService,
    private spinnerOverlayService: SpinnerOverlayService,
    private btlSharedLibraryService: BtlSharedLibraryService,
    public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.loadData();
  }

  pageChangeEvent(event: PageEvent) {
    this.dataSource.paginator = this.paginator;
  }

  onAcceptAction(row: any, id:any) {
    this.spinnerOverlayService.show();
    const requestBody = {
      artifactIds: [id],
    };
   
this.fieldType = row.updatedField;
    this.artifactsService.approveArtifacts(requestBody, this.user, this.fieldType, )
      .subscribe(response => {
        
        
        this.btlSharedLibraryService.updateStatusMessage(true, `${response.message}`, true);
        this.resetData(row.artifactId)

      }, error => {
        console.error('Error approving artifacts:', error);
        this.spinnerOverlayService.hide();
        const errorMessage = error.error && error.error.message ? error.error.message : "An error occurred during the request.";
        this.btlSharedLibraryService.updateStatusMessage(true, errorMessage, false);
      },
        () => {
          this.spinnerOverlayService.hide();
        }
      );
    window.scroll(0, 0)
  }
  onRejectAction(row: any, id:any) {
    this.spinnerOverlayService.show();
    const requestBody = {
      artifactIds: [id],
    };
    this.fieldType = row.updatedField;
    this.artifactsService.rejectArtifact(requestBody, this.user,this.fieldType)
      .subscribe(response => {
        console.log(' response:',response);
        this.spinnerOverlayService.hide();
        this.btlSharedLibraryService.updateStatusMessage(true, `${response.message}`, true)
        this.resetData(row.artifactId)
      }, error => {
        console.error('Error rejectung artifacts:', error);
        this.spinnerOverlayService.hide();
        const errorMessage = error.error && error.error.message ? error.error.message : "An error occurred during the request.";
        this.btlSharedLibraryService.updateStatusMessage(true, errorMessage, false);
      },
        () => {
          this.spinnerOverlayService.hide();
        }
      );
    window.scroll(0, 0)
  }
  loadData(): void {
    this.spinnerOverlayService.show();
    this.artifactsService.getApprovalArtifacts().subscribe({
      next: (data) => {
        if (data.status === '200 OK') {
          this.TableData = data.data;
          console.log("before foreach apply",this.TableData );
          console.log(JSON.stringify(data.data));
          this.TableData.forEach(item => {
            Object.defineProperty(item, 'displayArtifactId', { value: item.artifactId.split('_update_')[0], writable: true });
          });
          this.dataSource = new MatTableDataSource<Artifact>(this.TableData);
          this.pageLength = this.TableData.length;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        
        }
        this.spinnerOverlayService.hide();
      },
      error: (e) => {
        console.error(e);
        const errorMessage = e.error && e.error.message ? e.error.message : "An error occurred during the request.";
        console.log("Error ::",errorMessage);
      }
    })
    this.dataLoad = true;
    
  }

  triggerPaginatorFalseClick() {
    this.paginator?.nextPage();
    this.paginator?.previousPage();
  }
  rowData(row:any){
      
      const dialogRef = this.dialog.open(ArtifactsApprovalViewPopupComponent,{autoFocus: false,
        data:{
          rowdata:row
        }
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        console.log("res"+JSON.stringify(result));
      if (!result) return;
      this.onSubmit(result);
      });
  
  }

  onSubmit(data:any){
    console.log("closeloaddata",data);
    this.loadData;
  }
  openArtifactsApprovalDialog(): void {
    const dialogRef = this.dialog.open(ArtifactsApprovalViewPopupComponent,);
    dialogRef.afterClosed().subscribe((result: any) => {
    });
  }

  resetData(artifactIds: string[]) {
    console.log(this.dataSource)
    console.log("pre",artifactIds,this.TableData)
    

    this.TableData = this.TableData.filter((artifacts: any) => !artifactIds.includes(artifacts.artifactId))
    console.log("post",artifactIds,this.TableData)

    this.dataSource = new MatTableDataSource<Artifact>(this.TableData);
    this.loadData();
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	environment: "development",
	btlTpSubscriptionKey: '34e69733b31d4d5bbe4728358eb43c18',
    backendBaseUrl: 'https://catalystdev.bcnl.digitalaviationservices.com/btl-tp-editor',
	btlCommitmentSubscriptionKey: '52d2cc9f27824151b693024a22699587',
	btlCommitmentBaseUrl: 'https://catalystdev.bcnl.digitalaviationservices.com/commitment/api',
	cmsBaseUrl: 'https://catalystdev.bcnl.digitalaviationservices.com/cloud-cms-service',
  	cmsSubscriptionKey: '0569aa3413c14789af05b646c18be399',
	// checkAccessUrl: 'http://localhost:3000/api/check-access',
	checkAccessUrl: '/api/check-access',
	// backendBaseUrl: 'http://localhost:8080',
	// checkAccessUrl: 'http://localhost:3000/api/check-access',
	admin : "fp_btl_trainingpackage_application_admin_dev",
	editor : "fp_btl_trainingpackage_editor_dev",
	approver : "fp_btl_trainingpackage_approver_dev",
	readonly : "fp_btl_trainingpackage_readonly_dev"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

<!-- Edit Mode nav headers -->
<div class="edit-mode-headers rectangle" *ngIf="showEditPackageHeaders">
  <ul>
    <li class="edit-mode-info">
      <span class="edit-mode-text">EDIT MODE</span>
      <p class="edit-titles">
        <span class="edit-mode-subtitle-rectangle">{{editCount}}</span>
        <span class="edit-mode-subtitle-2">Training Packages</span>
      </p>
    </li>

    <li class="button-list">
      <button mat-raised-button (click)="savePackage()" [disabled]="editCount === 0">
        <mat-icon>description</mat-icon>
        SAVE
      </button>
      <button mat-raised-button (click)="showPreview()">
        <mat-icon>visibility</mat-icon>
        PREVIEW
      </button>
      <button mat-raised-button (click)="submitPackageForApproval()">
        <mat-icon class="material-symbols-outlined">upload</mat-icon>
        SUBMIT PACKAGE
      </button>
    </li>
  </ul>
</div>
<div class="edit-mode-headers rectangle" *ngIf="showPreviewPackageHeaders">
  <ul>
    <li class="edit-mode-info">
      <span class="edit-mode-text">PREVIEW MODE</span>
      <p class="edit-titles">
        <span class="edit-mode-subtitle-rectangle">{{editCount}}</span>
        <span class="edit-mode-subtitle-2">Training Packages</span>
      </p>
    </li>

    <li class="button-list">
      
      <button mat-raised-button *ngIf="this.currentPage==='editor'"(click)="backToEditMode()">
        <mat-icon class="back-arrow-icon">arrow_back_ios</mat-icon>
        BACK TO EDIT MODE
      </button>
      <button mat-raised-button *ngIf="this.currentPage==='approval'"(click)="navigateToApprovals()">
        <mat-icon class="back-arrow-icon">arrow_back_ios</mat-icon>
        BACK TO APPROVALS
      </button>
    </li>
  </ul>
</div>

<!--status message-->
<lib-status-message></lib-status-message>

<table mat-table [dataSource]="dataSource" class="artifact-approval-table">


    <ng-container matColumnDef="artifactId">
        <th mat-header-cell *matHeaderCellDef> Artifact ID </th>
        <td mat-cell *matCellDef="let element" class=" artifact"> {{element.displayArtifactId}} </td>
    </ng-container>

    <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef> View </th>
        <td mat-cell *matCellDef="let row" class="viewcell">
            <div class="d-flex actions-div " (click)="rowData(row)">
                <mat-icon class="material-icons-outlined view pointer">visibility</mat-icon>
                
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="field">
        <th mat-header-cell *matHeaderCellDef> Field</th>
        <td mat-cell *matCellDef="let element" class="fieldcell"> {{element.updatedField}} </td>
    </ng-container>

    <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef> Current </th>
        <td mat-cell *matCellDef="let element" class="currentcell">
            <div *ngIf = "element.updatedField === 'Title'">
                <div  class="title-div"> {{ element.current ? element.current.title : 'N/A' }}</div>
                    <div class="modified-by-div">{{ element.current ? element.current.createdBy : '' }}</div>
            </div>
            <div *ngIf = "element.updatedField === 'Description'">
                <div >{{ element.current ? element.current.description : 'N/A' }}</div>
                <div  class="modified-by-div"> {{ element.current ? element.current.createdBy : '' }}</div>
            </div>
            
        

        </td>
    </ng-container>

    <ng-container matColumnDef="update">
        <th mat-header-cell *matHeaderCellDef> Update </th>
        <td mat-cell *matCellDef="let element" class="updatecell">
            <label class="updateslabel" *ngIf="element.updates.length>1">UPDATES</label>
            <div (click)="rowData(element)" class="pointer">
            <ng-container *ngFor="let update of element.updates" >
                <div *ngIf = "element.updatedField === 'Title'">
                    <div *ngIf="element.updates.title !== null"class="title-div">{{update.title}}</div>
                </div>
                <div *ngIf = "element.updatedField === 'Description'">
                    <div *ngIf="element.updates.description !== null">{{update.description}}</div>
                </div>
            
            <div class="modified-by-div">  {{update.createdBy}}</div>
           <div class ="empty">   </div>
            </ng-container>
        </div>

        </td>
    </ng-container>

    <ng-container matColumnDef="submitted">
        <th mat-header-cell *matHeaderCellDef> Submitted(PST) </th>
        <td mat-cell *matCellDef="let element" class="submitted-cell">
            <ng-container *ngFor="let update of element.updates">
                
                <div class="submitted-part">{{update.modifiedAt | date:'yyyy-MM-dd'}}</div>
                <br>
            <div class="submitted-time-part">{{ update.modifiedAt | date:'hh:mm a' | lowercase }}</div>
            <br>
                </ng-container>
        </td>

    </ng-container>

    <ng-container matColumnDef="accept">
        <th mat-header-cell *matHeaderCellDef> Accept </th>
        <td mat-cell *matCellDef="let row">
            <ng-container *ngFor="let update of row.updates">
            <div class="d-flex actions-div">
                <i name="reject" class="material-icons-outlined reject-icon" (click)="onRejectAction(row, update.artifactId)">cancel</i>
                <i class="material-icons-outlined accept-icon" (click)="onAcceptAction(row, update.artifactId)">check_circle</i>
            </div>
            <div class ="empty_Accept_Reject">   </div>
        </ng-container>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator *ngIf="dataLoad" (page)="pageChangeEvent($event)" [length]="pageLength" [pageSize]="pageSize"
    [showTotalPages]="3" style-paginator [pageSizeOptions]="[3, 5, 10, 20]">
</mat-paginator>
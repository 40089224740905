<table class="draft-tbl">
    <tr>
        <td class="open-folder-td"><span class="material-symbols-outlined open-folder-icon">folder_open</span></td>
        <td class="middle-td">
            <div class="d-flex flex-column ">
                <span class="draft-lbl">DRAFT</span>
                <span class="intro-lbl">{{currentTask?.taskName}}</span>
                <!-- <p class="intro-caption-lbl">Make changes to content and Save your edits in a draft or submit for
                    approval when complete.</p> -->
                <div class="task-group-desc-div">
                    <span class="task-group-desc-title">Task Description</span>
                    <!-- <span class="task-group-desc-char-count"><strong>|</strong>{{currentTask?.taskDescription ?
                        (this.maxCharCount - this.descriptionValue.length): this.maxCharCount}}/{{this.maxCharCount}}
                        characters left</span> -->
                    <textarea class="task-group-desc-textarea" [(ngModel)]="descriptionValue"
                        cols="30" rows="4" (blur)="updateTaskDescription()">
                    </textarea>
                </div>
            </div>
        </td>
        <td class="last-td">
            
            <!-- <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon class="material-symbols-outlined more-vert-sym delete-task-vert">more_vert
                </mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="deleteTask()">
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                </button>
            </mat-menu> -->
            
        </td>
    </tr>
</table>
<app-artifact-section [artifacts]="artifacts" (artifactUpdateEvent)="handleArtifactUpdate($event)"
    (reOrderArtifactEvent)="handleReOrderArtifacts($event)">
</app-artifact-section>

<div class="add-artifact-circle" (click)="openArtifactDialog()">
</div>
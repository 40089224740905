<div class="container-fluid draft-tbl">
    <div class="row">
        <div class="col-md-12" >
            <h1>{{currentTask.taskName}}</h1>
            <p>{{currentTask.taskDescription}}</p>
        </div>
    </div>
    <div class="seperator"></div>
</div>

<div class="container-fluid draft-tbl" *ngFor="let artifact of artifacts">
    <div class="row">
        <div class="col-md-9">
            <h1>{{artifact.title}}</h1>
            <div class="icon-with-text">
                <span
                    class="material-symbols-outlined schedule-sym">{{getIconValueFromFilename(artifact.fileName)}}</span>
                <h6>{{artifact.fileName}}</h6>
            </div>
            <span class="description">Description</span>
            <p>{{artifact.description}}</p>
        </div>
        <div class="col-md-3">
            <div class="artifact-information">META DATA</div>
            <!-- <div class="metadata-box" *ngFor="let metaData of artifact.metaData index as metaDataIndex">
                <div class="metadata-header">{{metaData.name}}</div>
                <div>{{metaData.value}}</div>
            </div> -->

            <div *ngIf="artifact.metadata && artifact.metadata.metadataOrder; else loader">
                <div class="metadata-box" *ngFor="let key of artifact.metadata.metadataOrder; index as metaDataIndex">
                    <!-- <div *ngIf="artifact.metadata.artifactMetaData[key]" class="metadata-header">{{key}}</div> -->
                    <div class="metadata-header">{{key}}</div>
                    <div>{{artifact.metadata.artifactMetaData[key]}}</div>
                </div>
            </div>
            <ng-template #loader>
                <div class="loader">Loading...</div>
            </ng-template>
        </div>
    </div>
    <div class="row">
        <div class="col-md-9">

            <div class="artifact-buttons-div">
                <button class="btn-revision artifact-buttons-download" (click)="onClickDownloadArtifact(artifact)">
                    <span class="material-symbols-outlined schedule-sym">download</span>
                    DOWNLOAD
                </button>

                <button class="btn-revision artifact-buttons-mark-complete" (click)="markComplete(artifact)"
                    [class.completed]="artifact.isArtifactCompleted">
                    <span class="material-symbols-outlined schedule-sym">check_circle</span>
                    {{ artifact.isArtifactCompleted ? "I'VE COMPLETED" : "MARK COMPLETE" }}
                </button>
            </div>
        </div>
        <div class="col-md-3">
            <button class="btn-revision">
                <span class="material-symbols-outlined schedule-sym">schedule</span>
                REVISION
                <span class="material-symbols-outlined expand-sym">expand_more</span>
            </button>
        </div>
    </div>
    <div class="seperator"></div>
</div>